<!-- New Correspondence -->
<form [formGroup]="form">
<mat-card class="contact-details" layout="row" formGroupName="names">
    <mat-form-field formGroupName="realName">
        <input matInput type='text' placeholder='First' formControlName='firstName' />
    </mat-form-field>
    <mat-form-field formGroupName="realName">
        <input matInput type='text' placeholder='Middle' formControlName="middleName" />
    </mat-form-field>
    <mat-form-field formGroupName="realName">
        <input matInput type='text' placeholder='Last name' formControlName="lastName" />
    </mat-form-field>
    <mat-form-field>
        <input matInput type='text' placeholder='Alias' formControlName="alias" />
        <mat-hint *ngIf="showCustomError(['names'], 'eitherOr')" [ngStyle]="{'color': '#f44336'}">
            You must provide either a valid alias, or a first and last name, or both.
        </mat-hint>
    </mat-form-field>
    <mat-card>
        <!-- Contact methods -->
    </mat-card>
    <mat-card>
        <!-- Correspondences -->
    </mat-card>
</mat-card>
</form>