import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { 
  CorrespondenceFilterViewModel as CorFilter,
  CorrespondenceSubFilterViewModel as CorSubFilter,
  CorFilterType
} from '../../../view-models/filters';

@Component({
  selector: 'app-filter-viewer',
  templateUrl: './filter-viewer.component.html',
  styleUrls: ['./filter-viewer.component.css']
})
export class FilterViewerComponent implements OnInit {
  @Input("filter") filter: CorFilter;
  @Output() updated: EventEmitter<CorFilter> = new EventEmitter<CorFilter>();

  filterTypes: typeof CorFilterType = CorFilterType;
  constructor() { }

  ngOnInit() {
  }

  removeFilter(f: CorSubFilter) {
    let i = this.filter.Filters.indexOf(f);
    if (i >= 0)
      this.filter.Filters.splice(i, 1);
    this.updated.emit(this.filter);
  }

  switchSorting() {
    this.filter.Ascending = !this.filter.Ascending;
    this.updated.emit(this.filter);
  }
}
