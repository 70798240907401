import { BaseViewModel } from './base-view-model';
import { endpoint } from '../decorators/endpoint.decorator';
import { mappable } from '../decorators';
import { UserViewModel } from './user-view-model';
import { RoleViewModel } from './role-view-model';

@endpoint("userrole")
export class UserRoleViewModel extends BaseViewModel {
    public UserId: number = 0;
    public RoleId: number = 0;

    @mappable("UserViewModel")
    public User: UserViewModel = null;

    @mappable("RoleViewModel")
    public Role: RoleViewModel = null;
}