<section id="corr-details-container">
    <!-- Sub Header -->
    <app-header title='Select Review User'>
        <!-- Filter -->
        <mat-toolbar class="filter-toolbar">
          <!--<mat-toolbar-row>-->
            <form [formGroup]="form">
                <!-- https://angular.io/docs/ts/latest/guide/template-syntax.html#!#binding-syntax -->
                <fieldset>
                  <div fxLayout="row" fxLayoutAlign="center end">
                    <mat-form-field class="margin-right" fxFlex>
                      <input matInput type='text' placeholder='Search' [formControl]="keywords" />
                    </mat-form-field>
                    <mat-form-field>
                      <mat-select placeholder="Filter Role" [formControl]="userRole">
                        <mat-option *ngFor="let role of roles" [value]="role.Id" (click)='role.Id == null ? userRole.setValue(undefined) : null'>
                          {{ role.RoleName }}
                        </mat-option>
                      </mat-select>  
                    </mat-form-field>
                  </div>
                </fieldset>
            </form>
          <!--</mat-toolbar-row>-->
        </mat-toolbar>
    </app-header>
    <!-- User Chooser -->
    <div class="container">
      <section class="info-box">
        <h1 class="info-box-heading">Users</h1>
        <div class="info-box-wrapper">
          <div class="info-box-body">
            <mat-list class="info-box-list" fxLayout="column">
              <mat-list-item fxFlex *ngFor="let user of users; let i=index; let $last=last">
                  <div fxLayout="column" fxFlex='80'>
                    <h2 mat-line>{{ user.LastFirst }}</h2>
                    <p mat-line><strong>Roles:</strong> {{ user.RolesSummary }}</p>
                  </div>
                  <div fxFlex='20' fxLayoutAlign='end center'>
                    <button mat-icon-button mat-raised-button (click)='chooseUser(user)'>
                        <mat-icon class="mat-icon-dark">add</mat-icon>
                    </button>
                  </div>  
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </section>
    </div>
</section>