import { BaseFilterViewModel } from './base-filter-view-model';

export class IdwFilterViewModel extends BaseFilterViewModel {
    public Type: IdwFilterType = IdwFilterType.Keywords;
    public HawkId: string;
}

export enum IdwFilterType {
    /**
     * Search using the Keywords property across all IDW fields (email, hawkId, etc).
     */
    Keywords = 0x1,

    /**
     * Search using the HawkId field, only.
     */
    HawkId = 0x2
}