import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { RequestorCorrespondenceViewModel } from './requestor-correspondence-view-model';
import { CorrespondenceCategoryViewModel } from './correspondence-category-view-model';
import { RequestorViewModel } from './requestor-view-model';
import { endpoint, mappable } from '../decorators';

@endpoint('Count')
export class CorrCountViewModel extends BaseViewModel {
   Count: number = null;
}
