import { OnInit, Inject, Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Observer } from 'rxjs';


@Component({
  selector: 'download-dialog',
  templateUrl: './download.dialog.html',
  styleUrls: ['./download.dialog.css']
})

export class DownloadDialog implements OnInit {
  title: string;
  downloadId: number;
  message: string;
    constructor(
      public dialogRef: MatDialogRef<DownloadDialog>
    ) {
      
      //Observable.create()
    }

    ngOnInit() {
    }
}
