<section id="add-user-container">
    <app-header title="Add User">
      <!--  Filter -->
      <mat-toolbar class="filter-toolbar">
          <!--<mat-toolbar-row>-->
            <div fxLayout="row" fxLayoutAlign="start center">
              <div class='users-search'>
                <!-- https://angular.io/docs/ts/latest/guide/template-syntax.html#!#binding-syntax -->
                <mat-form-field fxFlex>
                  <input matInput type='text' placeholder='Search' [formControl]="keywords" />
                </mat-form-field> 
              </div>
            </div>
          <!--</mat-toolbar-row>-->
      </mat-toolbar>
    </app-header>
    <!-- Add New Correspondence Form -->
    <div class="container">
      <section class="info-box">
        <h1 class="info-box-heading">Available User List</h1>
        <div class="info-box-wrapper">
          <div class="info-box-body">
            <mat-list class="info-box-list">
              <mat-list-item fxLayout="row" flex *ngFor="let user of users; let i=index; let $last=last" (click)='addUser(user)'>
                  <div fxLayout="column">
                    <h2 mat-line>{{ user.LastFirst }} ({{ user.HawkId }})</h2>
                    <p mat-line>{{ user.Title }}, {{ user.Department }}</p>
                  </div>
                  <button mat-icon-button mat-raised-button>
                      <mat-icon class="mat-icon-dark">add</mat-icon>
                  </button>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </section>
    </div>
</section>