<section id="user-manager-container">
    <!-- Sub Header -->
    <app-header title='User Manager'>
        <!-- Filter -->
        <mat-toolbar class="filter-toolbar">
          <!--<mat-toolbar-row>-->
            <form [formGroup]="form">
                <!-- https://angular.io/docs/ts/latest/guide/template-syntax.html#!#binding-syntax -->
                <fieldset>
                  <div class="user-manager-filter" fxLayout="row" fxLayoutAlign="center end" fxFlex>
                    <mat-form-field class="margin-right" fxFlex>
                      <input matInput type='text' placeholder='Search' [formControl]="keywords" />
                    </mat-form-field>
                    <mat-form-field>
                      <mat-select placeholder="Filter Role" fxFlex [formControl]="userRole">
                          <mat-option> - All - </mat-option>
                          <span *ngFor="let role of roles">
                        <mat-option [value]="role.Id" *ngIf="role.RoleName !== ''">
                          {{ role.RoleName }}
                        </mat-option>
                          </span>
                      </mat-select>
                    </mat-form-field>  
                  </div>
                </fieldset>
            </form>
          <!--</mat-toolbar-row>-->
        </mat-toolbar>
    </app-header>
    <!-- User Management -->
    <div class="container">
      <section class="info-box">
        <h1 class="info-box-heading">Users</h1>
        <div class="info-box-wrapper">
          <div class="info-box-body">
            <mat-list class="info-box-list" fxLayout="column">
              <mat-list-item fxFlex *ngFor="let user of users| orderBy: order; let i=index; let $last=last">
                  <div fxLayout="column" fxFlex='80'>
                    <h2 mat-line>{{ user.LastFirst }}</h2>
                    <p mat-line><strong>Roles:&nbsp;</strong> {{ user.RolesSummary }}</p>
                  </div>
                  <div fxFlex='20' fxLayoutAlign='end center'>
                    <button mat-icon-button mat-raised-button [routerLink]="['/users', user.Id]">
                        <mat-icon class="mat-icon-dark">edit</mat-icon>
                    </button>
                    <button mat-icon-button mat-raised-button (click)='removeUser(user)'>
                        <mat-icon class="mat-icon-dark">remove</mat-icon>
                    </button>
                  </div>
                </mat-list-item>
            </mat-list>
          </div>
          <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
              <button mat-button mat-raised-button [routerLink]="['add']">Add User</button>
          </div>
        </div>
      </section>
    </div>
</section>
