import { ILogService } from './i';
import { InjectionToken } from '@angular/core';

export class ConsoleWrapper {
    error(...args: any[]): void { if (console && console.error) console.error.apply(this, args); }
    log(...args: any[]): void { if (console && console.log) console.log.apply(this, args); }
    warn(...args: any[]): void { if (console && console.warn) console.warn.apply(this, args); }
    debug(...args: any[]): void { if (console && console.debug) console.debug.apply(this, args); }
}

export class LogService extends ConsoleWrapper implements ILogService {
    public constructor(
        private _err: boolean = true,
        private _log: boolean = true,
        private _warn: boolean = true,
        private _debug: boolean = true)
    {
        super();
    }

    error(message?: any, ...optional: any[]): void {
        if (this._err && typeof message !== "undefined") {
            optional.unshift(message);
            super.error.apply(this, optional);
        }
    }

    log(message?: any, ...optional: any[]): void {
        if (this._log && typeof message !== "undefined") {
            optional.unshift(message);
            super.log.apply(this, optional);
        }
    }

    warn(message?: any, ...optional: any[]): void {
        if (this._warn && typeof message !== "undefined") {
            optional.unshift(message);
            super.log.apply(this, optional);
        }
    }

    debug(message?: any, ...optional: any[]): void {
        if (this._debug && typeof message !== "undefined") {
            optional.unshift(message);
            super.debug.apply(this, optional);
        }
    }
}

export let LogServiceToken = new InjectionToken("ILogService");