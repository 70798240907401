import { HttpParams } from '@angular/common/http';

const endpointMetadataKey = "cortrack.endpoint.decorator";
export function endpoint(uri: string) {
    return (target: Function) => {
        Reflect.set(target, endpointMetadataKey, uri);
    };
}

export function getEndpoint(target: Function): string {
    return Reflect.get(target, endpointMetadataKey);
}
