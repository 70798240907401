import { HttpParams } from '@angular/common/http';
import { BaseFilterViewModel } from './base-filter-view-model';
import { CorrespondenceFilterViewModel } from './correspondence-filter-view-model';
import { Operator } from './operator';
import { mappable } from '../../decorators';
import { CorFilterType } from './correspondence-subfilter-view-model';

export class CorrCountFilterViewModel extends BaseFilterViewModel {
    @mappable("CorrespondenceSubFilterViewModel")
    public Filters: CorrespondenceFilterViewModel[] = [];

    public getAsHttpParams(): HttpParams {
        let params = new HttpParams();
        for (var h=0; h<this.Filters.length; h++) {

            if (this.Filters[h].Filters.length == 0) {
                // Return all
                params = params.append(`filter[${h}].Filters[0].Type`, `${CorFilterType.Category}`);
                params = params.append(`filter[${h}].Filters[0].Op`, `${Operator.ApiContains}`);
                continue;
            }

            for(var i=0; i<this.Filters[h].Filters.length; i++) {
                params = params.append(`filter[${h}].Filters[${i}].Type`, `${this.Filters[h].Filters[i].Type}`);
                params = params.append(`filter[${h}].Filters[${i}].Op`, `${this.Filters[h].Filters[i].Op}`);
                for (var j=0; j<this.Filters[h].Filters[i].Keywords.length; j++)
                    params = params.append(`filter[${h}].Filters[${i}].Keywords`, `${this.Filters[h].Filters[i].Keywords[j]}`);
            }
        }
        return params;
    }
}
