import { Injectable, Inject, InjectionToken } from '@angular/core';
import { AuthenticationService, AuthenticationServiceToken } from './auth.service';
import { LogServiceToken } from './log.service';
import { IRoleService, ILogService } from './i';


// Originally from http://jasonwatmore.com/post/2016/08/16/angular-2-jwt-authentication-example-tutorial

@Injectable()
export class RoleService implements IRoleService {
    constructor(
        @Inject(AuthenticationServiceToken) private authService: AuthenticationService,
        @Inject(LogServiceToken) private log: ILogService) {}
    public canActivate(routeFrag: string, params: { [key: string]: string }): boolean {
        var routeFrag = routeFrag[0] == "/" ? routeFrag.substr(1) : routeFrag;
        switch(routeFrag) {
        case "correspondences":
        case "correspondences/:filter":
        case "correspondence": 
        case "correspondence/:id":
        case "download/:id":
            return this.authService.isAuthenticated && this.authService.hasAnyRole("Admin", "Shepherd", "Reviewer", "Intake");
        case "correspondence/:id/reviews/add":
        case "correspondence/:cid/reviews/:rid":
        case "correspondence/:cid/reviews/:rid/documents/add":
            return this.authService.isAuthenticated && this.authService.hasAnyRole("Admin", "Shepherd", "Reviewer");
        case "correspondence/add":
        case "correspondence/:id/documents/add":
            return this.authService.isAuthenticated && this.authService.hasAnyRole("Admin", "Shepherd", "Intake");
        case "contacts":
        case "contacts/:id":
        case "contacts/:id/details/:id":
            return this.authService.isAuthenticated && this.authService.hasAnyRole("Admin", "Shepherd", "Intake");
        case "users":
        case "users/add":
        case "users/:id":
        case "choose/user":
            return this.authService.isAuthenticated && this.authService.hasAnyRole("Admin", "Shepherd");
        case "settings":
            return this.authService.isAuthenticated && this.authService.hasAnyRole();
        default:
            this.log.warn(routeFrag + " is not a known route. Authorization denied by default.");
        }

        return false;
    }
}

export let RoleServiceToken = new InjectionToken("IRoleServiceToken");

