export enum Operator {
    /** Whether the API data is greater than the input data. */
    ApiGreaterThan = 0x1,

    /** Whether the API data is less than the input data. */
    ApiLessThan = 0x2,

    /** Whether the API data is equal to the input data. */
    ApiEquals = 0x3,

    /** Whether the API data contains the input data. */
    ApiContains = 0x4,

    /** Whether the API data is between the given input points. */
    ApiBetween = 0x5,
}