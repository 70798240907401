
import {debounceTime, map} from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { CorrespondenceFilterViewModel } from '../../view-models';
import { CorrFilterServiceToken } from '../../services';
import { ICorrFilterService } from '../../services/i';

@Component({
  selector: 'app-filtered-dashboard',
  templateUrl: './filtered-dashboard.component.html',
  styleUrls: ['./filtered-dashboard.component.css']
})
export class FilteredDashboardComponent implements OnInit {
  public filter: Observable<CorrespondenceFilterViewModel>;
  public filterName: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(CorrFilterServiceToken) private corrFilterSvc: ICorrFilterService,
  ) {
  }

  ngOnInit() {
    this.filter = this.route.params.pipe(
      debounceTime(100),
      map(params => {
      this.filterName = params['filter'];
      let filter = this.corrFilterSvc.getFilter(this.filterName);
      return filter;
    }));
  }

}
