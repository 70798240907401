
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { RepositoryServiceToken } from '../../services';
import { IRepositoryService } from '../../services/i';
import { RequestorViewModel } from '../../view-models';
import { BaseFilterViewModel } from '../../view-models/filters';

@Component({
  selector: 'app-manage-contacts',
  templateUrl: './manage-contacts.component.html',
  styleUrls: ['./manage-contacts.component.css']
})
export class ManageContactsComponent implements OnInit {
  requestors: RequestorViewModel[] = [];
  filter: BaseFilterViewModel = new BaseFilterViewModel();
  @Input() keywords: FormControl = new FormControl();
  @Input() userRole: FormControl = new FormControl();
  @Input() form: FormGroup = new FormGroup({ keywords: this.keywords, role: this.userRole });

  // http://blog.angular-university.io/introduction-to-angular-2-forms-template-driven-vs-model-driven/
  // Regarding subscribe and emit: https://scotch.io/tutorials/angular-2-http-requests-with-observables
  // Automapper (if really necessary): https://github.com/loedeman/AutoMapper/wiki
  constructor(
    @Inject(RepositoryServiceToken) private repo: IRepositoryService
  ) {
    this.keywords.valueChanges.pipe(
      debounceTime(400))
      .subscribe(keywords => {
        this.filter.Keywords = keywords.split();
        this.repo.Page(RequestorViewModel, this.filter)
          .subscribe(value => { this.requestors = value; });
      });
  }

  ngOnInit() {
    this.keywords.setValue("");
    /*var roleFilter = new BaseFilterViewModel();
    roleFilter.PageSize = 50;
    this.repo.Page(RoleViewModel, roleFilter)
      .subscribe(roles => this.roles = roles);*/
  }
}
