<section id="user-details-container">
    <!-- Sub Header -->
    <app-header title='User Details'></app-header>
    <!-- User Details -->
    <div class="container">
        <section class="info-box">
          <!-- https://angular.io/docs/ts/latest/guide/template-syntax.html#!#binding-syntax -->
          <h1 class="info-box-heading">{{ user && user.FullName }}</h1>
            <div class="info-box-wrapper">
                <div class="info-box-body">
                  <div class="section-container" fxLayout="column">
                    <h2>Email</h2>
                    <p>{{ user && user.Email }}</p>
                  </div>
                  <div class="section-container">
                    <h2>Role<span class="comma-seperated" *ngIf="user && user.UserRoles.length > 0">s</span></h2>
                    <mat-list fxLayout="column" *ngIf="user != null">
                        <mat-list-item fxLayout="column" *ngFor="let urole of user.UserRoles; let i=index; let $last=last">
                            <p fxFlex>{{ urole && urole.Role && urole.Role.RoleName }}</p>
                            <button mat-icon-button mat-raised-button (click)='removeRole(urole)'>
                                <mat-icon class="mat-icon-dark">remove</mat-icon>
                            </button>
                        </mat-list-item>
                    </mat-list>
                    <div class="add-user-role" fxLayout="row">
                        <div class="form-field-container new-reviewer" fxLayout="row">
                            <mat-form-field fxFlex='80'>
                              <mat-select class="margin-right" placeholder="Please select a user role" [formControl]="userRoleControl">
                                <mat-option *ngFor="let role of roles" [value]="role.Id">{{ role.RoleName }}</mat-option>
                              </mat-select>    
                            </mat-form-field>
                            <div fxFlex>
                              <button type="button" style="margin-left: 25px;" mat-button mat-raised-button (click)="addRole()">Add Role</button>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
                    <button class="btn-save" mat-button mat-raised-button (click)="removeUser()">Remove User</button>
                    <button mat-button mat-raised-button [routerLink]='[".."]'>Done</button>
                </div>
            </div>
      </section>      
  </div>
</section>
