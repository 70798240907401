import { Injectable, Inject, InjectionToken } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { APIBaseURI } from '../app.config.tokens';

@Injectable()
export class ProgressService {
  constructor(
    @Inject(APIBaseURI) private baseUri: string
  ) { }

  progressEventObservable:Subject<any> = new Subject();
  
  // TODO flesh this out... allow filtering, etc.
  public subscribe(fn: (value: any) => void, er: (value: any) => void): Subscription {
    return this.progressEventObservable.subscribe(fn, er);
  }

}

export let ProgressServiceToken = new InjectionToken("ProgressService");