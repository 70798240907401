import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IAuthenticationService } from '../../services/i';
import { AuthenticationService, AuthenticationServiceToken} from '../../services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
@Injectable()
export class LoginComponent implements OnInit {
  constructor(
    private router: Router,
    @Inject(AuthenticationServiceToken) private authService: AuthenticationService
  ) {
      // FIXME - should we do something more with nagivation errors?
      this.router.navigate(["correspondences"]).catch(r => r);
  }

  ngOnInit() {
  }

}
