import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras } from '@angular/router';
import { IRoleService } from '../services/i';
import { RoleServiceToken, LogServiceToken } from '../services';
import { ILogService } from '../services/i';

@Injectable()
export class RoleGuard implements CanActivate {

    constructor(
        private router: Router,
        @Inject(RoleServiceToken) private roleService: IRoleService,
        @Inject(LogServiceToken) private log: ILogService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return true; 
        
        this.log.debug("can activate?", route.routeConfig.path);
        var can = this.roleService.canActivate(route.routeConfig.path, route.queryParams);
        this.log.debug("can: ", can);
        if (can) return can;

        this.router.navigate(['/help'], <NavigationExtras>{ fragment: "authorization" });
        return false;
    }
}