import { HttpParams } from '@angular/common/http';
import { IFilterViewModel } from './i/i-filter-view-model';

export class BaseFilterViewModel implements IFilterViewModel {
    public Page: number = 0;
    public PageSize: number = 10;
    public Keywords: string[];

    getAsHttpParams(): HttpParams {
        let params = new HttpParams()
        .set('filter.page', `${this.Page}`)
        .set('filter.pageSize', `${this.PageSize}`);
        for (let i in this.Keywords) {
          params = params.append('filter.keywords', this.Keywords[i]);
        }
            // console.log(params);
            return params;
        }
    }



