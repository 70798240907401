<section id="corr-details-container">
    <app-header title='Correspondence Details'></app-header>
    <div class="container">
        <!-- View Mode -->
        <section class="info-box" *ngIf="!editMode">
            <h1 class="info-box-heading">Correspondence</h1>
            <div class="info-box-wrapper">
                <div class="info-box-body">
                    <div class="info-box-sections">
                        <div class="info-section">
                            <h2>Date Received</h2>
                            <p>{{ myForm.get('DateReceived').value }}</p>
                        </div>
                        <div class="info-section">
                            <h2>Due Date</h2>
                            <p>{{ myForm.get('DueDate').value }}</p>
                        </div>
                        <div class="info-section">
                            <h2>Status</h2>
                            <p>{{ myForm.get('Status').value }}</p>
                        </div>
                    </div>
                    <div class="section-container col-mat-12">
                        <h2>Incoming/Outgoing</h2>
                        <p>{{ myForm.get('Type').value }}</p>
                    </div>
                    <div class="section-container col-mat-12">
                        <h2>Priority</h2>
                        <p>{{ myForm.get('Priority').value }}</p>
                    </div>
                    <div class="section-container col-mat-12">
                        <h2>Received Through</h2>
                        <p>{{ myForm.get('ReceivedThrough').value }}</p>
                    </div>
                    <div class="section-container col-mat-12" fxLayout="column">
                        <h2>Categories</h2>
                        <p>
                            <span class='comma-seperated' *ngFor="let corCat of (correspondence || {}).CorrespondenceCategories">
                                {{corCat.Category.Name}}</span>
                        </p>
                    </div>
                    <div class="section-container col-mat-12">
                        <h2>Requestor Name</h2>
                        <p>{{ myForm.get('RequestorFullName').value }}</p>
                    </div>
                    <div class="section-container col-mat-12">
                        <h2>Requestor Details/Notes</h2>
                        <p>{{ myForm.get('RequestorDetails').value }}</p>
                    </div>
                    <div class="section-container col-mat-12">
                        <h2>Address</h2>
                        <p>{{ myForm.get('RequestorAddress').value }}</p>
                    </div>
                    <div class="section-container input-group col-mat-12" fxLayout="row">
                        <div class="input-group-field">
                            <h2>City</h2>
                            <p>{{ myForm.get('RequestorCity').value }}</p>
                        </div>
                        <span style="width: 25px;"></span>
                        <div class="input-group-field">
                            <h2>State</h2>
                            <p>{{ myForm.get('RequestorState').value }}</p>
                        </div>
                        <span style="width: 25px;"></span>
                        <div class="input-group-field">
                            <h2>Zip</h2>
                            <p>{{ myForm.get('RequestorZip').value }}</p>
                        </div>
                    </div>
                    <div class="section-container input-group col-mat-12" fxLayout="row">
                        <div class="input-group-field">
                            <h2>Phone Number</h2>
                            <p>{{ myForm.get('RequestorPhone').value }}</p>
                        </div>
                        <span style="width: 25px;"></span>
                        <div class="input-group-field">
                            <h2>Email</h2>
                            <p>{{ myForm.get('RequestorEmail').value }}</p>
                        </div>
                    </div>
                    <div class="section-container col-mat-12">
                        <h2>Title</h2>
                        <p>{{ myForm.get('Title').value }}</p>
                    </div>
                    <div class="section-container col-mat-12">
                        <h2>Summary</h2>
                        <p>{{ myForm.get('Summary').value }}</p>
                    </div>
                    <div class="section-container col-mat-12">
                        <h2>Notes</h2>
                        <p>{{ myForm.get('Notes').value }}</p>
                    </div>
                </div>
                <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end" *ngIf="canIntake">
                    <button mat-button mat-raised-button (click)="enableEditing()">Edit</button>
                </div>
            </div>
        </section>
        <!-- Edit Mode -->
        <section *ngIf="editMode">
            <form id="corr-edit" class="info-box" [formGroup]="myForm" (ngSubmit)="saveChanges()">
                <h1 class="info-box-heading">Edit Correspondence</h1>
                <div class="info-box-wrapper">
                    <div class="info-box-body">
                        <fieldset>  
                            <!-- dates -->
                            <div class="info-box-sections">         
                                <!-- date received -->                    
                                <div class="info-section input" fxLayout="column" fxLayoutAlign="center center" flex>
                                    <mat-form-field>
                                        <input placeholder="Date Received &#42;" matInput type='date' formControlName="DateReceived" />
                                        <mat-hint *ngIf="showRequiredError(['DateReceived'])" [ngStyle]="{'color': '#f44336'}">
                                            Please specify when this correspondence was received.
                                        </mat-hint>
                                        <mat-hint *ngIf="showCustomError(['DateReceived'], 'afterToday') == true" [ngStyle]="{'color': '#f44336'}">
                                            The date received must be in the past.
                                        </mat-hint>
                                    </mat-form-field>
                                </div>
                                <!-- due date -->
                                <div class="info-section input" fxLayout="column" fxLayoutAlign="center center" flex>
                                    <mat-form-field mat-is-error="!form.get('DueDate').valid">
                                        <input placeholder="Due Date &#42;" matInput type='date' formControlName="DueDate" />
                                        <mat-hint *ngIf="showRequiredError(['DueDate'])" [ngStyle]="{'color': '#f443366'}">
                                            Please specify when a response is due.
                                        </mat-hint>
                                        <mat-hint *ngIf="showCustomError(['DueDate'], 'beforeTomorrow') == true" [ngStyle]="{'color': '#f44336'}">
                                            The due date must be sometime after today.
                                        </mat-hint>
                                    </mat-form-field>
                                </div>
                                <!-- status -->
                                <div class="info-section input" fxLayout="column" fxLayoutAlign="center center" flex>
                                    <mat-form-field>
                                        <mat-select id="status" fxLayout="column" placeholder="Status &#42;" formControlName="Status">
                                            <mat-option value='Intake'>Intake</mat-option>
                                            <mat-option value='Assigned'>Assigned</mat-option>
                                            <mat-option value='Completed'>Completed</mat-option>
                                        </mat-select>                                        
                                        <mat-hint *ngIf="showRequiredError(['Status'])" [ngStyle]="{'color': '#f443366'}">
                                            Please specify a status.
                                        </mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div fxLayout='row'>
                            <mat-hint *ngIf="myForm?.errors?.datesRequired"  [ngStyle]="{'color': '#f44336'}">
                                You must enter the above fields when In/Out type is Incoming.
                            </mat-hint>
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="form-field-container" fxLayout="row">
                                <div class="select-lists two-col margin-right" fxLayout="column">
                                    <!-- in/out -->
                                    <mat-form-field>
                                        <mat-select placeholder="In/Out &#42;" formControlName="Type">
                                            <mat-option value='Incoming'>Incoming</mat-option>
                                            <mat-option value='Outgoing'>Outgoing</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- priority -->                                    
                                        <mat-form-field>
                                            <mat-select class="form-field-container" placeholder="Priority &#42;" formControlName="Priority">
                                                <mat-option *ngFor='let p of priorities' [value]='p'>{{p}}</mat-option>
                                            </mat-select>                                   
                                            <mat-hint *ngIf="showRequiredError(['Priority'])" [ngStyle]="{'color': '#f443366'}">
                                                Please specify a priority.
                                            </mat-hint>
                                        </mat-form-field>
                                    <!-- received through -->                               
                                    <mat-form-field>
                                        <mat-select class="form-field-container" placeholder="Received Through &#42;" formControlName="ReceivedThrough">
                                            <mat-option *ngFor='let r of receivedThrough' [value]='r'>{{r}}</mat-option>
                                        </mat-select>
                                        <mat-hint *ngIf="showRequiredError(['ReceivedThrough'])" [ngStyle]="{'color': '#f44336'}">
                                            Please specify received method.
                                        </mat-hint>
                                    </mat-form-field>
                                </div>      
                                <!-- category -->
                                <div class="two-col" fxLayout="column">
                                    <label>Category &#42;</label>
                                    <div class="multi-select">
                                        <select placeholder="Category" multiple size=3 formControlName="Category">
                                            <option 
                                                *ngFor="let cat of categories"
                                                [value]="cat.Id">
                                                    {{cat.Name}}
                                            </option>
                                        </select>
                                        <mat-hint *ngIf="showRequiredError(['Category'])" [ngStyle]="{'color': '#f44336'}">
                                                Please select categories for this correspondence.
                                        </mat-hint>
                                    </div>
                                </div>                   
                            </div>
                        </fieldset>
                        <fieldset>
                            <!-- name -->
                            <div class="form-field-container" fxLayout="row">
                                <mat-form-field class="margin-right" fxFlex>
                                    <input matInput type="text" placeholder="First Name" formControlName='RequestorFirstName' />
                                </mat-form-field>
                                <mat-form-field class="margin-right" fxFlex>
                                    <input matInput type="text" placeholder="Middle Name" formControlName='RequestorMiddleName' />
                                </mat-form-field>
                                <mat-form-field fxFlex>
                                    <input matInput type="text" placeholder="Last Name" formControlName='RequestorLastName' />
                                </mat-form-field>
                                <!-- <mat-hint *ngIf="showRequiredError('RequestorFirstName') || showRequiredError('RequestorLastName')" [ngStyle]="{'color': '#f44336'}">
                                    Please specify the first and last name.
                                </mat-hint> -->
                            </div>
                            <!-- details -->
                            <div class="form-field-container col-mat-12" fxLayout="column">
                                <mat-form-field>
                                    <input matInput type='text' placeholder='Contact Details' formControlName='RequestorDetails' />
                                </mat-form-field>
                            </div>
                        </fieldset>
                        <fieldset>
                            <!-- address -->
                            <div class="form-field-container col-mat-12" fxLayout="column">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Address" formControlName='RequestorAddress' />
                                </mat-form-field>
                            </div>
                            <div class="form-field-container col-mat-12" fxLayout="row">
                                <mat-form-field class="margin-right" fxFlex="60">
                                    <input matInput type="text" placeholder="City" formControlName='RequestorCity' />
                                </mat-form-field>
                                <mat-form-field class="margin-right" fxFlex>
                                    <input matInput type="text" placeholder="State" [matAutocomplete]='stateAuto' formControlName='RequestorState' />
                                </mat-form-field>
                                <mat-autocomplete #stateAuto="matAutocomplete" fxFlex>
                                <mat-option *ngFor="let state of states" [value]="state">
                                    {{ state }}
                                </mat-option>
                                </mat-autocomplete>
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Zip" formControlName='RequestorZip' />
                                </mat-form-field>
                            </div>
                            <!-- phone & email -->
                            <div class="form-field-container col-mat-12" fxLayout="row">
                                <mat-form-field class="margin-right" fxFlex>
                                    <input matInput type="text" placeholder="Phone" formControlName='RequestorPhone' />
                                </mat-form-field>
                                <mat-form-field fxFlex>
                                    <input matInput type="email" placeholder="Email" formControlName='RequestorEmail' />
                                </mat-form-field>
                            </div>
                        </fieldset>
                        <fieldset>
                            <!-- title & summary -->
                            <div class="form-field-container col-mat-12" fxLayout="column">
                                <mat-form-field>
                                    <input placeholder="Title &#42;" matInput type='text' formControlName="Title" />
                                    <mat-hint *ngIf="showRequiredError(['Title'])" [ngStyle]="{'color': '#f44336'}">
                                        Please specify the correspondence subject or title.
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="form-field-container col-mat-12" fxLayout="column">
                                <mat-form-field>
                                    <textarea placeholder="Summary" matInput type='text' formControlName="Summary"></textarea>
                                    <mat-hint *ngIf="showRequiredError(['Summary'])" [ngStyle]="{'color': '#f44336'}">
                                        Please specify the correspondence summary.
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="form-field-container col-mat-12" fxLayout="column">
                                <mat-form-field>
                                    <textarea placeholder="Notes" matInput type='text' formControlName="Notes"></textarea>
                                </mat-form-field>
                            </div>
                        </fieldset>    
                    </div>
                    <!-- save & cancel button -->          
                    <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
                        <button class="btn-save" mat-button mat-raised-button (click)="saveChanges()" [disabled]="!myForm.valid">Save</button>
                        <button mat-button mat-raised-button (click)="cancelEdits()">Cancel</button>
                    </div>
                </div>
            </form>
        </section>
        <!-- Add Attachments -->
        <section class="info-box" *ngIf="canSeeAttachments">
            <h1 class="info-box-heading">Attachments</h1>
            <div class="info-box-wrapper">
                <div class="info-box-body">
                    <mat-list class="info-box-list" fxLayout="column">
                        <mat-list-item *ngFor="let doc of documentsObs | async; let i=index;">
                            <h2 mat-line>{{ doc?.Filename }} {{ doc?.Size | prettySize }}</h2>                                
                            <button mat-icon-button mat-raised-button (click)="download(doc.Id)">
                                <mat-icon class="mat-icon-dark">file_download</mat-icon>
                            </button>                          
                            <button mat-icon-button mat-raised-button (click)='removeFile(doc)'>
                                <mat-icon class="mat-icon-dark">remove</mat-icon>
                            </button>
                        </mat-list-item>
                    </mat-list>
                </div>                
                <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
                    <button mat-button mat-raised-button [routerLink]="['./documents/add']">Add</button>
                </div>
            </div>
        </section>
        <!-- Add Reviews -->
        <a name='reviews'></a>
        <section class="info-box" *ngIf="canSeeReviews">
            <h1 class="info-box-heading">Reviews</h1>
            <div class="info-box-wrapper">
                <div class="info-box-body block-list">                    
                    <mat-list>
                        <mat-list-item style='height: 100%;' *ngFor="let review of reviewsObs | async; let i=index;">
                            <div [routerLink]="['./reviews', review.Id]">
                                <div class="corr-list-heading" fxLayout="row" fxLayoutAlign="space-between center">
                                    <h2 mat-subheader>{{ review.Created | amDateFormat:'MMM DD'  }}</h2>
                                    <div class="element">{{ review.ReviewStatus }}</div>
                                </div>
                                <mat-divider style="position: relative;"></mat-divider>
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <div fxLayout="column">
                                        <h3 mat-line>Reviewer: {{ review.User?.FullName || "Unknown assignee" }}</h3>
                                        <p mat-line class="corr-list-summary">{{ review.ReviewText?.substr(0,105) }}{{ review.ReviewText?.length > 105 ? "..." : "" }}</p>
                                        <p mat-line *ngIf="review && review.ReviewDocuments">
                                            <span><b>Documents:&nbsp;</b></span>
                                            <span *ngFor="let rd of review.ReviewDocuments; let i=index;">
                                                {{ rd.Document && rd.Document.Filename }} ({{ rd.Document && rd.Document.Size | prettySize }})
                                            </span>
                                        </p>
                                    </div>
                                    <mat-icon>chevron_right</mat-icon>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end center" *ngIf="auth.hasAnyRole('Admin', 'Shepherd')">                                
                                <button mat-button mat-raised-button (click)='sendReminder(review.Id)'>Send Reminder</button>
                                <button mat-button mat-raised-button (click)='deleteReview(review.Id)'>Delete</button>
                            </div>
                        </mat-list-item>
                    </mat-list>
                </div>
                <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
                    <button *ngIf="isAdmin" mat-button mat-raised-button [routerLink]="['./reviews/add']">Assign Reviewer</button>
                </div>
            </div>
        </section>
    </div>
</section>
