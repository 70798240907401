<section id="settings-container">
    <app-header title='User Settings'></app-header>
    <div class='container'>
      <section>
        <form class="info-box" [formGroup]="settingsForm">
            <!-- https://angular.io/docs/ts/latest/guide/template-syntax.html#!#binding-syntax -->
            <h1 class="info-box-heading">{{ user && user.FullName }}</h1>
            <div class="info-box-wrapper">
              <div class="info-box-body">
                <div class="section-container" fxLayout="column">
                  <h2>Role</h2><p>{{ user && user.RolesSummary }}</p>
                </div>
                <fieldset>
                  <div class="section-container">
                    <mat-form-field fxFlex>
                      <input matInput type='text' placeholder='Email' formControlName="Email" />
                    </mat-form-field>
                  </div>
                  <div class="section-container">
                    <mat-radio-group fxLayout="column" formControlName='EmailNotifications'>
                      <mat-radio-button value='1'>Email notifications ON</mat-radio-button>
                      <mat-radio-button value='0'>Email notifications OFF</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </fieldset>
              </div>
              <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
                <button class="btn-save" mat-button mat-raised-button (click)='save()' [disabled]="!settingsForm.valid">Save</button>
                <button mat-button mat-raised-button type='button' [routerLink]='["/", "correspondences"]'>Cancel</button>
              </div>
            </div>
        </form>
      </section>
    </div>
</section>