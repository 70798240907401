import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { DocumentFileViewModel } from './document-file-view-model';
import { CorrespondenceDocumentViewModel } from './correspondence-document-view-model';
import { ReviewDocumentViewModel } from './review-document-view-model';
import { endpoint } from '../decorators/endpoint.decorator';
import { mappable } from '../decorators/mappable.decorator';

@endpoint("Document")
export class DocumentViewModel extends BaseViewModel {
    public Filename: string = "";
    public ContentType: string = "";
    public Size: number = 0;

    @mappable("DocumentFileViewModel")
    public DocumentFile: DocumentFileViewModel = null;

    @mappable("CorrespondenceDocumentViewModel")
    public CorrespondenceDocument: CorrespondenceDocumentViewModel = null;

    @mappable("ReviewDocumentViewModel")
    public ReviewDocument: ReviewDocumentViewModel = null;

    public UUID: string = null;
}
