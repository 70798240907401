import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
//import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { IJsonAuthTokenViewModel as IJWT, UserViewModel } from '../view-models';
import { LogServiceToken } from './log.service';
import { IAuthenticationService, ILogService } from './i';
import { CommunityAuthService } from './community-auth.service';


// Originally from http://jasonwatmore.com/post/2016/08/16/angular-2-jwt-authentication-example-tutorial

@Injectable()
export class AuthenticationService {
    constructor(
        private oAuth: OAuthService,
        private communityAuth: CommunityAuthService,
        //private helper: JwtHelperService,
        @Inject(LogServiceToken) private log: ILogService) {}
    private decodedToken: any = null;
    get token(): string { return this.oAuth.getAccessToken(); }
    get isAuthenticated(): boolean { return !! this.token && ! this.isSessionExpired; }
    get username(): string { return this.jsonToken && this.jsonToken.sub || null; }
    get roles(): string[] {
        const user = this.getUserInfo<UserViewModel>();
        return user?.UserRoles?.map(r => r?.Role?.RoleName);
        /*return this.jsonToken && (typeof(this.jsonToken.role) == "string"
            ? [ this.jsonToken.role ]
            : this.jsonToken.role) || [];*/
    }
    get isSessionExpired(): boolean { return this.oAuth.getAccessTokenExpiration() < Date.now(); }
    get sessionExpiration(): Date { return new Date(this.oAuth.getAccessTokenExpiration()); }
    get jsonToken(): IJWT { return this.oAuth.getIdentityClaims() as IJWT; }
    get stateDescription(): string {
        if (this.token && this.isSessionExpired)
            return "Session expired.";
        else if (this.isAuthenticated)
            return "Logged in.";
        else
            return "Not logged in.";
    }

    public getToken(): any {
      // console.log('here i am    ' , this.token);
        return this.token;
    }

    public getAccept(): string {
      return 'application/json';
    }
    authorizeRequest(headers: Headers): Headers {
        headers.append("Accept", "application/json");
        headers.append("Authorization", "Bearer " + this.token);
        return headers;
    }
    hasRole(role: string): boolean { return this.roles && this.roles.indexOf(role) != -1 || false; }
    hasAnyRole(...rs: string[]): boolean {
        if (rs.length == 0)
            return this.roles.length > 0;

        return this.roles && rs.filter(r => this.roles.indexOf(r) != -1).length > 0;
    }
    hasAllRoles(...rs: string[]): boolean {
        if (rs.length == 0)
            throw new Error("No roles requested in order to be authorized. Is this a typo?");
        return this.roles && rs.filter(r => this.roles.indexOf(r) != -1).length == rs.length;
    }
    beginLoginFlow(): void { this.oAuth.initImplicitFlow(); }
    checkForLogin(): Promise<IJWT> { return this.oAuth.tryLogin().then(() => <any>this.oAuth.getIdentityClaims()); }
    setUserInfo<T>(user: T): void {
        localStorage.setItem("userInfo", JSON.stringify(user));
    }
    getUserInfo<T>(): T {
        let user = localStorage.getItem("userInfo");
        try {
            return JSON.parse(user);
        } catch(err) {
            return null;
        }
    }
    logout(): void { this.oAuth.logOut(); }
}

export let AuthenticationServiceToken = new InjectionToken("IAuthenticationServiceToken");

