<div *ngIf='filter'>
  <mat-chip-list>
    <mat-chip (click)='switchSorting()'>Sort: 
      <mat-icon *ngIf="filter.Ascending">arrow_upwards</mat-icon>
      <mat-icon *ngIf="!filter.Ascending">arrow_downwards</mat-icon>
    </mat-chip>
    <ng-container *ngFor="let f of filter.Filters">
      <mat-chip *ngIf="f.Type == filterTypes.CommunicationType" (click)='removeFilter(f)' color='primary'>Type: {{ f.Keywords[0] }} <span class="close-chip">x</span></mat-chip>
      <mat-chip *ngIf="f.Type == filterTypes.Category" (click)='removeFilter(f)' color='primary'>Cat.: {{ f.Keywords[0] }} <span class="close-chip">x</span></mat-chip>
      <mat-chip *ngIf="f.Type == filterTypes.Keywords" (click)='removeFilter(f)' color='accent'>{{ f.Keywords.join(", ") }} <span class="close-chip">x</span></mat-chip>
      <mat-chip *ngIf="f.Type == filterTypes.Requestor" (click)='removeFilter(f)' color='warn'>Contact: {{ f.Keywords.join(" ") }} <span class="close-chip">x</span></mat-chip>
      <mat-chip *ngIf="f.Type == filterTypes.Status" (click)='removeFilter(f)'>Status: {{ f.Keywords.join(" or ") }} <span class="close-chip">x</span></mat-chip>
      <mat-chip *ngIf="f.Type == filterTypes.Reviewer" (click)='removeFilter(f)'>Reviewer: {{ f.Keywords.join(" ") }}  <span class="close-chip">x</span>
      </mat-chip>
    </ng-container>
  </mat-chip-list>
</div>