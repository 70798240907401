import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { endpoint } from '../decorators/endpoint.decorator';
import { RequestorViewModel } from './requestor-view-model';
import { mappable } from '../decorators';

@endpoint("contactdetail")
export class ContactDetailViewModel extends BaseViewModel {
    public RequestorId: number = 0;
    public AddressType: string = "";
    public Phone: string = "";
    public Email: string = "";
    public Address1: string = "";
    public Address2: string = "";
    public City: string = "";
    public State: string = "";
    public Country: string = "";
    public PostalCode: string = "";
    public PreferContactBy: string = "";
    public PrimaryContact: boolean = false;
    public Notes: string = "";

    /** The person with whom this contact detail record is associated. */
    @mappable("RequestorViewModel")
    public Requestor: RequestorViewModel;
}
