import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "prettySize" })
export class PrettySize implements PipeTransform {
    transform(value: any): string {
        if (value > 1 << 20) {
            let f = "" + (value / (1 << 20));
            let i = f.indexOf(".");
            return (i > -1 ? f.substr(0, i + 2) : f) + "Mb";
        }
        if (value > 1024) {
            let f = "" + (value / (1 << 10));
            let i = f.indexOf(".");
            return (i > -1 ? f.substr(0, i + 2) : f) + "Kb";
        }

        return `${value} bytes`;
    }
}