import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { RequestorCorrespondenceViewModel } from './requestor-correspondence-view-model';
import { CorrespondenceCategoryViewModel } from './correspondence-category-view-model';
import { RequestorViewModel } from './requestor-view-model';
import { endpoint, mappable } from '../decorators';

@endpoint("Correspondence")
export class CorrespondenceViewModel extends BaseViewModel {
    /** The type of the correspondence (incoming, outgoing, etc) */
    public Type: string = "";

    /** This could be an email correspondence subject line, or intake staff's own title. */
    public Title: string = "";

    /** Could be the body of the email correspondence, or intake staff's summary. */
    public Summary: string = "";

    /** A short description of the correspondence source. E.g., email, text, phone, letter, news article, etc */
    public ReceivedThrough: string = "";

    /** The people sending/signing this correspondence. */
    @mappable("RequestorCorrespondenceViewModel")
    public RequestorCorrespondences: RequestorCorrespondenceViewModel[] = [];

    /** Requestor free type field */
    public RequestorName: string = "";

    /** Requestor address, other details free type field. */
    public RequestorDetail: string = "";

    /** When the University received the correspondence. */
    public DateReceived: Date = null;

    /** When a reply is due, if ever. */
    public DueDate: Date = null;

    /** Status of the correspondence. */
    public Status: string = "";

    /** Intake and misc notes */
    public Notes: string = "";

    public Priority: string = "";

    public CorrespondenceCategories: CorrespondenceCategoryViewModel[] = null;

    public get PrimaryRequestor(): RequestorViewModel {
        var p = (this.RequestorCorrespondences || []).filter(rc => rc.IsPrimary);
        if (p.length >= 1)
            return p[0].Requestor;
        if ((this.RequestorCorrespondences || []).length)
            return this.RequestorCorrespondences[0].Requestor;
        return null;
    }

    public get RequestorSummary(): string {
        return (this.RequestorCorrespondences || []).map(rc => rc.Requestor && rc.Requestor.LastName || "").join(", ");
    }
}

export let Statuses: string[] = [
    "Intake", "Assigned", "Completed", "Pending AVP"
];