<div class='contacts-search'>
  <form [formGroup]="form">
    <div id="filters">
      <!-- https://angular.io/docs/ts/latest/guide/template-syntax.html#!#binding-syntax -->
      <mat-form-field class='flex'>
        <input matInput type='text' placeholder='Search...' [formControl]="keywords" />
      </mat-form-field>    
    </div>
  </form>
</div>
<div class="contacts-list" layout="row">
    <mat-nav-list>
      <mat-list-item class="mat-2-line" *ngFor="let person of requestors; let i=index; let $last=last"
        [routerLink]="[person.Id]">
          <h3 mat-line>{{ person.LastFirst }}{{ person.Alias ? ', AKA "' + person.Alias + '""' : "" }}</h3>
          <p mat-line>{{ person.OrgSummary }}</p>
      </mat-list-item>
      <!--<mat-divider mat-inset hide-sm *ngIf="!$last"></mat-divider>-->
      <!--<mat-divider hide-gt-sm *ngIf="!$last"></mat-divider>-->
    </mat-nav-list>
</div>