<section id="add-corr-container">
    <app-header title="Add Correspondence"></app-header>
    <!-- Add New Correspondence Form -->
    <div class="container">
        <form [formGroup]="myForm" (ngSubmit)="onsubmit()">
            <section class="info-box">
                <h1 class="info-box-heading">Correspondence Details</h1>
                <div class="info-box-wrapper">
                    <fieldset class="info-box-body">
                        <!-- details -->
                        <div class="corr-details info-box-sections" fxLayout="row">
                            <!-- in/out, priority, category -->
                            <div class="info-section" fxLayoutAlign="center center" fxFlex>
                                <mat-form-field>
                                    <mat-select placeholder="In/Out &#42;" formControlName="Type">
                                        <mat-option value='Incoming'>Incoming</mat-option>
                                        <mat-option value='Outgoing'>Outgoing</mat-option>
                                    </mat-select>
                                    <mat-hint *ngIf="showRequiredError(['Type'])" [ngStyle]="{'color': '#f44336'}">
                                        Please specify Incoming or Outgoing correspondence.
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="info-section" fxLayoutAlign="center center" fxFlex>
                                <mat-form-field>
                                    <mat-select placeholder="Priority &#42;" formControlName="Priority">
                                        <mat-option *ngFor='let p of priorities' [value]='p'>{{p}}</mat-option>
                                    </mat-select>
                                    <mat-hint *ngIf="showRequiredError(['Priority'])" [ngStyle]="{'color': '#f44336'}">
                                        Please specify the correspondence priority.
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="info-section" fxLayoutAlign="center center" fxFlex>
                                <div fxLayout="column" class="multi-select">
                                    <label>Category &#42;</label>
                                    <select multiple placeholder='Category' formControlName="Category">
                                        <option *ngFor="let cat of categories" [value]="cat.Id">{{cat.Name}}</option>
                                    </select>
                                    <mat-hint *ngIf="showRequiredError(['Category'])" [ngStyle]="{'color': '#f44336'}">
                                        Please select categories for this correspondence.
                                    </mat-hint>
                                </div>
                            </div>
                        </div>
                        <!-- dates -->
                        <div class="corr-details info-box-sections" fxLayout="row" *ngIf='myForm.controls["Type"].value == "Incoming"'>
                            <div class="info-section" fxLayoutAlign="center center" fxFlex>
                                <mat-form-field>
                                    <mat-select placeholder="Received Through &#42;" formControlName="ReceivedThrough">
                                        <mat-option *ngFor='let r of receivedThrough' [value]='r'>{{r}}</mat-option>
                                    </mat-select>
                                    <mat-hint *ngIf="showRequiredError(['ReceivedThrough'])" [ngStyle]="{'color': '#f44336'}">
                                        Please specify received method.
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="info-section input" fxLayoutAlign="center center" fxFlex>
                                <mat-form-field>
                                    <input matInput type='date' placeholder='Date Received &#42;' formControlName="DateReceived" />
                                    <mat-hint *ngIf="showRequiredError(['DateReceived'])" [ngStyle]="{'color': '#f44336'}">
                                        Please specify when this correspondence was received.
                                    </mat-hint>
                                    <mat-hint *ngIf="showCustomError(['DateReceived'], 'beforeTomorrow') == true" [ngStyle]="{'color': '#f44336'}">
                                        The date received must be today or in the past.
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                            <div class="info-section input" fxLayoutAlign="center center" fxFlex>
                                <mat-form-field mat-is-error="! form.get('DueDate').valid">
                                    <input matInput type='date' placeholder='Due Date &#42;' formControlName="DueDate" />
                                    <mat-hint *ngIf="showRequiredError(['DueDate'])">
                                        Please specify when a response is due.
                                    </mat-hint>
                                    <mat-hint *ngIf="showCustomError(['DueDate'], 'afterToday')" [ngStyle]="{'color': '#f44336'}">
                                        The due date must be sometime after today.
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout='row'>
                            <mat-hint *ngIf="myForm?.errors?.datesRequired"  [ngStyle]="{'color': '#f44336'}">
                                You must enter the above fields when In/Out type is Incoming.
                            </mat-hint>
                        </div>
                    </fieldset>
                </div>
            </section>
            <section class="info-box" formGroupName="NewRequestor">
                <h1 class="info-box-heading">Contact Details</h1>
                <div class="info-box-wrapper">
                    <fieldset class="info-box-body">
                        <!-- name -->
                        <div class="form-field-container" fxLayout="row">
                            <mat-form-field fxFlex class="margin-right">
                                <input matInput type="text" placeholder="First Name" formControlName='FirstName' />
                            </mat-form-field>
                            <mat-form-field fxFlex class="margin-right">
                                <input matInput type="text" placeholder="Middle Name" formControlName='MiddleName' />
                            </mat-form-field>
                            <mat-form-field fxFlex>
                                <input matInput type="text" placeholder="Last Name" formControlName='LastName' />
                            </mat-form-field>
                        </div>
                        <!-- details -->
                        <div class="form-field-container" fxLayout="column">
                            <mat-form-field>
                                <input matInput type='text' placeholder='Requestor Details' formControlName='Details' />
                            </mat-form-field>
                        </div>
                        <!-- address -->
                        <div class="form-field-container" fxLayout="column">
                            <mat-form-field>
                                <input matInput type="text" placeholder="Address" formControlName='Address' />
                            </mat-form-field>
                        </div>
                        <div class="form-field-container" fxLayout="row">
                            <mat-form-field class="margin-right" fxFlex>
                                <input matInput type="text" placeholder="City" formControlName='City' />
                            </mat-form-field>
                            <!-- PLACEHOLDER STATE FORM FIELD -->
                            <mat-form-field class="margin-right" fxFlex>
                                <input matInput type="text" placeholder="State" formControlNae='State' />
                            </mat-form-field>
                            <!-- PLACEHOLDER STATE FORM FIELD -->
                            <!--<mat-form-field class="margin-right">
                                <input matInput type="text" placeholder="State" [matAutocomplete]='stateAuto' formControlName='State' />
                            </mat-form-field>
                            <mat-autocomplete #stateAuto="matAutocomplete">
                            <mat-option *ngFor="let state of states" [value]="state">
                                {{ state }}
                            </mat-option>
                            </mat-autocomplete>-->
                            <mat-form-field>
                                <input matInput type="test" placeholder="Zip" formControlName='Zip' />
                            </mat-form-field>
                        </div>
                        <!-- phone & email -->
                        <div class="form-field-container" fxLayout="row">
                            <mat-form-field class="margin-right" fxFlex>
                                <input matInput type="text" placeholder="Phone" pattern="(\(\d{3}\)?\s?\d{3}-?\d{4})|\+?(\d{11})|(\d{5})" formControlName='Phone' />
                            </mat-form-field>
                            <mat-form-field fxFlex>
                                <input matInput type="email" placeholder="Email" formControlName='Email' />
                            </mat-form-field>
                        </div>
                    </fieldset>
                </div>
            </section>
            <section class="info-box">
                <h1 class="info-box-heading">Correspondence Content</h1>
                <div class="info-box-wrapper">
                    <fieldset class="info-box-body">
                        <div class="form-field-container" fxLayout="column">
                            <mat-form-field>
                                <input matInput type='text' placeholder='Title &#42;' formControlName="Title" />
                                <mat-hint *ngIf="showRequiredError(['Title'])" [ngStyle]="{'color': '#f44336'}">
                                    Please specify the correspondence subject or title.
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="form-field-container" fxLayout="column">
                            <mat-form-field>
                                <textarea matInput type='text' placeholder='Summary' formControlName="Summary"></textarea>
                                <mat-hint *ngIf="showRequiredError(['Summary'])" [ngStyle]="{'color': '#f44336'}">
                                    Please specify the correspondence details.
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="form-field-container" fxLayout="column">
                            <mat-form-field>
                                <textarea matInput type='text' placeholder='Notes' formControlName="Notes"></textarea>
                            </mat-form-field>
                        </div>
                    </fieldset>
                    <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
                        <button class="btn-save" mat-button mat-raised-button type="submit" [disabled]="!myForm.valid">Submit</button>
                        <button mat-button mat-raised-button type='button' (click)='goBack()'>Cancel</button>
                    </div>
                </div>
            </section>
        </form>
    </div>
</section>