import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { endpoint, mappable } from '../decorators';
import { ContactDetailViewModel } from './contact-detail-view-model';
import { RequestorCorrespondenceViewModel } from './requestor-correspondence-view-model';

@endpoint("requestor")
export class RequestorViewModel extends BaseViewModel {
    /** An internal nickname given by President's Office staff to frequent contacts. */
    public Alias: string = "";

    /** The primary title of the contact. */
    public Title: string = "";
    /** The organization or affiliation on behalf of whom the requestor is corresponding. */
    public Affiliation: string = "";

    /** Staff notes on this requestor. */
    public Notes: string = "";
    public FirstName: string = "";
    public MiddleName: string = "";
    public LastName: string = "";
    public get FullName(): string { return `${this.FirstName} ${this.MiddleName || ""} ${this.LastName}`; }
    public get LastFirst(): string { return `${this.LastName}, ${this.FirstName} ${this.MiddleName || ""}`.trim(); }
    public get OrgSummary(): string {
        if (this.Title && this.Affiliation)
            return `${this.Title}, ${this.Affiliation}`;
        else if (this.Title)
            return this.Title;
        else if (this.Affiliation)
            return this.Affiliation
        else
            return "";
    }

    @mappable("RequestorCorrespondenceViewModel")
    public RequestorCorrespondences: RequestorCorrespondenceViewModel[] = [];

    @mappable("ContactDetailViewModel")
    public ContactDetails: ContactDetailViewModel[] = [];
}
