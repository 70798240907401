import { Injectable, Inject, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class ScrollService {

    private scroll$: Observable<any>;
    private scrollObserver: any;

    constructor() {
        this.scroll$ = new Observable(observer => {
            this.scrollObserver = observer
        });
    }

    getObservable(): Observable<any> {
        return this.scroll$;
    }

    scroll($event) {
        this.scrollObserver.next($event);
    }
}

export let ScrollServiceToken = new InjectionToken("IScrollServiceToken");