import { Component, OnInit, Inject, Injectable, EventEmitter, Input, ViewContainerRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { RepositoryServiceToken, MapperServiceToken, LogServiceToken, AuthenticationServiceToken, AuthenticationService } from '../../services';
import { IRepositoryService, IMapperService, ILogService, IAuthenticationService } from '../../services/i';
import { CorrespondenceViewModel, ReviewViewModel, ReviewDocumentViewModel, DocumentViewModel, ReviewStatuses } from '../../view-models';
import { BaseFilterViewModel as CorFilter, ReviewDocumentFilterViewModel, ReviewDocFilterType } from '../../view-models/filters';
import { IFilterViewModel } from '../../view-models/filters/i';
import { ConfirmDialog, DownloadDialog } from '../../dialogs';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css']
})
@Injectable()
export class ReviewComponent implements OnInit {
  editMode: boolean = false;
  review: ReviewViewModel;
  documents: DocumentViewModel[] = [];
  corr: CorrespondenceViewModel;
  @Input() myForm: FormGroup;
  dialogRef: MatDialogRef<ConfirmDialog>;
  downloadDialogRef: MatDialogRef<DownloadDialog>;
  statuses: { key: string, value: string }[] = ReviewStatuses;

  constructor(
    @Inject(AuthenticationServiceToken) private auth: AuthenticationService,
    @Inject(RepositoryServiceToken) private repo: IRepositoryService,
    @Inject(MapperServiceToken) private mapper: IMapperService,
    @Inject(LogServiceToken) private log: ILogService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private vcr: ViewContainerRef
  ) {
    this.myForm = this.buildForm();


    //this.myForm.disable();

    this.route.params.subscribe(params => {
      let cid: number = +params['cid'];
      let rid: number = +params['rid'];
      this.repo.Get(CorrespondenceViewModel, cid)
        .toPromise()
        .then(corr => { this.corr = corr; });

      this.repo.Get(ReviewViewModel, rid)
        .toPromise()
        .then(rev => {
          this.setupForm(rev);
          this.review = rev;
        });

      this.refreshDocuments(rid);
    });
  }

  removeFile(doc: DocumentViewModel) {
    this.openDialog(`Are you sure you want to delete ${doc.Filename}?`).then(result => {
      if (result) {
        this.repo.Delete(ReviewDocumentViewModel, doc.ReviewDocument.Id).toPromise().then(d => {
          this.refreshDocuments(this.review.Id);
        });
      }
    });
  }

  deleteReview() {
     this.openDialog(`Are you sure you want to delete this review?`).then(result => {
      if (result) {
        this.repo.Delete(ReviewViewModel, this.review.Id).toPromise().then(d => {
          this.goToCorrespondence();
        });
      }
    });   
  }

  sendReminder() {
    this.repo.Update(ReviewViewModel, <ReviewViewModel>{ Remind: true, Id: this.review.Id })
      .toPromise()
      .then(r => {
        this.toastr.success(`Reminder email sent to ${r.User.FullName}.`);
      });
  }

  refreshDocuments(id: number) {
    let docFilter = new ReviewDocumentFilterViewModel();
    docFilter.ReviewId = id;
    docFilter.Type = ReviewDocFilterType.ByReviewId;
    this.repo.Page(ReviewDocumentViewModel, docFilter)
      .subscribe(rdocs => {
        this.documents = rdocs.map(rd => {
          rd.Document.ReviewDocument = rd;
          return rd.Document;
        });
    });
  }

  download(id: number) {
    this.downloadDialogRef = this.dialog.open(
      DownloadDialog, {
      disableClose: false
    });
    this.downloadDialogRef.componentInstance.title = "Beginning secure download...";
    this.downloadDialogRef.componentInstance.downloadId = id;

    this.downloadDialogRef.afterClosed().subscribe(result => {
      this.downloadDialogRef = null;
    });
    return this.downloadDialogRef.afterClosed().toPromise();    
  }

  buildForm(): FormGroup {
    return this.fb.group({
      review: [''],
      status: ['', Validators.required],
      instructions: ['']
    });
  }

  setupForm(r: ReviewViewModel): void {
    this.myForm.get('review').setValue(r.ReviewText);
    this.myForm.get('status').setValue(r.ReviewStatus);
    this.myForm.get('instructions').setValue(r.Instructions);
  }

  openDialog(message: string): Promise<any> {
    this.dialogRef = this.dialog.open(
      ConfirmDialog, {
      disableClose: false
    });
    this.dialogRef.componentInstance.title = "Confirm";
    this.dialogRef.componentInstance.message = message;

    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
    return this.dialogRef.afterClosed().toPromise();
  }

  goToCorrespondence() {
    let text = this.myForm.get("review").value;
    let status = this.myForm.get("status").value;
    if (text != this.review.ReviewText || status != this.review.ReviewStatus) {
      this.openDialog("You seem to have unsaved changes. Are you sure you want to cancel?")
        .then(yes => {
          if (yes) {
            this.router.navigate(["/correspondence", this.corr.Id]);
          }
        })
    } else {
      this.router.navigate(["/correspondence", this.corr.Id]);
    }
  }

  saveChanges() {
    if (this.myForm.errors != null) {
      this.log.warn("Form not valid.", this.myForm.errors);
      return;
    }

    let raw = this.myForm.getRawValue();
    this.review.ReviewText = raw.review;
    this.review.ReviewStatus = raw.status;
    this.review.Instructions = raw.instructions;

    if (this.myForm.errors == null)
        this.repo.Update(ReviewViewModel, this.review)
          .toPromise()
          .then(this.onUpdate.bind(this));
  }

  onUpdate(updated: ReviewViewModel) {
    this.review = updated;
    this.setupForm(updated);
    this.editMode = false;
    let rt = this.review.ReviewText ? this.review.ReviewText.trim() : "";
    let ct = this.corr.Summary ? this.corr.Summary.trim() : "";
    let message = [
      "Review ",
      rt.length ? `"${rt.substr(0, 20)}${rt.length > 20 ? '...' : ''}" ` : "",
      "has been updated",
      ct.length ? ` for "${ct.substr(0, 20)}${ct.length > 20 ? '...' : ''}".` : ".",
    ].join('');
    this.toastr.success(message, "Success");
  }

  cancelEdit() {
    this.setupForm(this.review);
    this.editMode = false;
  }

  editReview() {
    this.setupForm(this.review);
    this.editMode = true;
  }

  ngOnInit() { }
}
