import { BaseViewModel } from './base-view-model';
import { UserRoleViewModel } from '.';
import { endpoint, mappable } from '../decorators';

@endpoint("role")
export class RoleViewModel extends BaseViewModel {
    public RoleName: string = "";
    public Description: string = "";

    @mappable("UserRoleViewModel")
    public UserRoles: UserRoleViewModel[];
}