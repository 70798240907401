
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Component, OnInit, Inject, Injectable, EventEmitter, Input, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment/moment';
import { ToastrService } from 'ngx-toastr';

import { states, priorities, receivedThrough } from '../../static-data';
import { RepositoryServiceToken, MapperServiceToken, AuthenticationServiceToken, LogServiceToken, AuthenticationService } from '../../services';
import { IRepositoryService, IMapperService, IAuthenticationService, ILogService } from '../../services/i';
import { 
  CorrespondenceViewModel as VMCorr, 
  CorrespondenceDocumentViewModel as VMCorDoc,
  CorrespondenceCategoryViewModel, 
  DocumentViewModel,
  CategoryViewModel,
  ContactDetailViewModel, RequestorViewModel,
  RequestorCorrespondenceViewModel,
  DocumentViewModel as VMDoc,
  ReviewViewModel } from '../../view-models';
import { 
  BaseFilterViewModel as CorFilter, 
  CorrespondenceDocumentFilterViewModel as CorDocFilter,
  CorDocFilterType,
  ReviewFilterType,
  ReviewFilterViewModel
} from '../../view-models/filters';
import { IFilterViewModel } from '../../view-models/filters/i';
import { BeforeTomorrowValidator, AfterTodayValidator } from '../add-correspondence/validators';
import { ConfirmDialog, DownloadDialog } from '../../dialogs';

@Component({
  selector: 'app-correspondence',
  templateUrl: './correspondence.component.html',
  styleUrls: ['./correspondence.component.css']
})
@Injectable()
export class CorrespondenceComponent implements OnInit {
  editMode: boolean = false;
  submitAttempted: boolean = false;
  filter: IFilterViewModel = new CorFilter();
  correspondence: VMCorr = null;
  documentsObs: Observable<DocumentViewModel[]>;
  reviewsObs: Observable<ReviewViewModel[]>;
  categories: CategoryViewModel[] = [];
  activeCategories: any[] = [];
  states: string[] = states;
  priorities: string[] = priorities;
  receivedThrough: string[] = receivedThrough;

  canIntake: boolean = false;
  canSeeAttachments: boolean = false;
  canSeeReviews: boolean = false;
  isAdmin: boolean = false;

  @Input() keywords: FormControl = new FormControl();
  @Input() myForm: FormGroup;

  dialogRef: MatDialogRef<ConfirmDialog>;
  downloadDialogRef: MatDialogRef<DownloadDialog>;

  // http://blog.angular-university.io/introduction-to-angular-2-forms-template-driven-vs-model-driven/
  // Regarding subscribe and emit: https://scotch.io/tutorials/angular-2-http-requests-with-observables
  // Automapper (if really necessary): https://github.com/loedeman/AutoMapper/wiki
  constructor(
    @Inject(RepositoryServiceToken) private repo: IRepositoryService,
    @Inject(MapperServiceToken) private mapper: IMapperService,
    @Inject(AuthenticationServiceToken) private auth: AuthenticationService,
    @Inject(LogServiceToken) private log: ILogService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private vcr: ViewContainerRef
  ) {

    this.myForm = this.buildCorrespForm();

    //this.myForm.disable();
    var filter = new CorFilter();
    filter.PageSize = 30;
    this.repo.Page(CategoryViewModel,filter).subscribe(cats => {
      this.categories = cats; //cats.map(c => { return { id: c.Id, text: c.Name }; });
    });      

    this.route.params.subscribe(params => {
      let id: number = +params['id'];
      this.refresh(id);
    });

    this.isAdmin = auth.hasAnyRole('Admin', 'Shepherd');
    this.canSeeReviews = this.isAdmin || auth.hasAnyRole('Reviewer');
    this.canIntake = this.isAdmin || auth.hasAnyRole('Intake') || auth.hasAnyRole('Reviewer');
    this.canSeeAttachments = this.isAdmin || auth.hasAnyRole('Intake') || auth.hasAnyRole('Reviewer');
  }

  openDialog(message: string): Promise<any> {
    this.dialogRef = this.dialog.open(
      ConfirmDialog, {
      disableClose: false
    });
    this.dialogRef.componentInstance.title = "Confirm Deletion";
    this.dialogRef.componentInstance.message = message;

    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
    return this.dialogRef.afterClosed().toPromise();
  }

  download(id: number) {
    this.downloadDialogRef = this.dialog.open(
      DownloadDialog, {
      disableClose: false
    });
    this.downloadDialogRef.componentInstance.title = "Beginning secure download...";
    this.downloadDialogRef.componentInstance.downloadId = id;

    this.downloadDialogRef.afterClosed().subscribe(result => {
      this.downloadDialogRef = null;
    });
    return this.downloadDialogRef.afterClosed().toPromise();    
  }

  showRequiredError(fieldPath: string[]): boolean {
    return this.submitAttempted && this.myForm.hasError('required', fieldPath);
  }

  showCustomError(fieldPath: string[], error: string): boolean {
    return this.submitAttempted && this.myForm.hasError(error, fieldPath);
  }

  enableEditing() {
    this.editMode = true;
  }

  cancelEdits() {
    this.editMode = false;
    this.setupCorrForm(this.correspondence);
  }

  submitting: boolean = false;
  saveChanges() {
    if (this.submitting) return;

    this.submitAttempted = true;
    let corr = this.mapper.MapJsonToVM(VMCorr, this.myForm.value);
    corr.Status = this.myForm.get('Status').value;
    corr.Id = this.correspondence.Id;
    if (this.myForm.invalid) {
      this.log.warn("Form not valid.", this.myForm.errors);
      return;
    }

    this.submitting = true;
    let r = this.myForm.value;
    let req = (this.correspondence.RequestorCorrespondences || [])[0];
    let corrUpdate = this.repo.Update(VMCorr, corr).toPromise(); //.subscribe(this.onUpdate.bind(this), e => this.log.warn(e));
    let contact: RequestorViewModel, details: ContactDetailViewModel;
    contact = (req && req.Requestor) || new RequestorViewModel();
    details = (contact.ContactDetails || [])[0] || new ContactDetailViewModel();
    contact.FirstName = r.RequestorFirstName;
    contact.MiddleName = r.RequestorMiddleName;
    contact.LastName = r.RequestorLastName;
    contact.Notes = r.RequestorDetails;
    details.Address1 = r.RequestorAddress;
    details.City = r.RequestorCity;
    details.State = r.RequestorState;
    details.PostalCode = r.RequestorZip;
    details.Phone = r.RequestorPhone;
    details.Email = r.RequestorEmail;
    let promise;
    if (details.Id > 0) {
      promise = observableForkJoin(
        corrUpdate,
        this.categoryUpdates(),
        this.repo.Update(RequestorViewModel, contact).toPromise(),
        this.repo.Update(ContactDetailViewModel, details).toPromise(),
      ).toPromise()
      .then(arr => { return this.onUpdate(arr[0]); });
    } else if (contact.Id > 0) {
      details.RequestorId = contact.Id;
      promise = observableForkJoin(
        corrUpdate,
        this.categoryUpdates(),
        this.repo.Update(RequestorViewModel, contact).toPromise(),
        this.repo.Create(ContactDetailViewModel, details).toPromise()
      ).toPromise();
    } else {
      promise = observableForkJoin(
        corrUpdate,
        this.categoryUpdates(),
        this.repo.Create(RequestorViewModel, contact)
        .toPromise()
        .then(r => {
          details.Id = 0;
          details.RequestorId = r.Id;
          var join = new RequestorCorrespondenceViewModel();
          join.CorrespondenceId = this.correspondence.Id;
          join.RequestorId = r.Id;
          let rdp = this.repo.Create(RequestorCorrespondenceViewModel, join).toPromise();
          let cdp = this.repo.Create(ContactDetailViewModel, details).toPromise();
          return observableForkJoin(rdp, cdp).toPromise();
        })
      ).toPromise();
    }
    promise
      .then(arr => { this.submitting = false; this.refresh(this.correspondence.Id); })
      //.then(arr => { return this.onUpdate(arr[0]); })
      .catch(e =>  { this.submitting = false; this.log.warn(e); });
  }

  categoryUpdates(): Promise<[CorrespondenceCategoryViewModel[], CorrespondenceCategoryViewModel[]]> {
    let curCats = this.correspondence.CorrespondenceCategories;
    let newccvms: CorrespondenceCategoryViewModel[] = [];

    for (let u of this.myForm.value.Category)
    {
        let ccm = new CorrespondenceCategoryViewModel ();
        ccm.Id=0;
        ccm.CorrespondenceId=this.correspondence.Id;
        ccm.CategoryId=u;
        newccvms.push(ccm);
    }

    // delete the current categories that don't exist among the newly selected.
    let delIds = curCats.filter(c => newccvms.map(n => n.CategoryId).indexOf(c.CategoryId) === -1).map(c => c.Id);
    // create only the new ones that don't already exist on the server.
    newccvms = newccvms.filter(c => curCats.map(cc => +cc.CategoryId).indexOf(+c.CategoryId) === -1);
    
    let deletes = delIds.map(id => this.repo.Delete(CorrespondenceCategoryViewModel, id).toPromise());

    return observableForkJoin(
      this.repo
        .CreateMany(CorrespondenceCategoryViewModel, newccvms)
        .toPromise(),
      observableForkJoin(deletes)
    ).toPromise();        
  }

  deleteReview(id: number) {
     this.openDialog(`Are you sure you want to delete this review?`).then(result => {
      if (result) {
        this.repo.Delete(ReviewViewModel, id).toPromise().then(d => {
          this.refreshReviews(this.correspondence.Id);
        });
      }
    });   
  }

  sendReminder(id: number) {
    this.repo.Update(ReviewViewModel, <ReviewViewModel>{ Remind: true, Id: id })
      .toPromise()
      .then(r => {
        this.toastr.success(`Reminder email sent to ${r.User.FullName}.`);
      });
  }

  removeFile(doc: DocumentViewModel) {
    this.openDialog(`Are you sure you want to delete ${doc.Filename}?`).then(result => {
      if (result) {
        this.repo.Delete(VMCorDoc, doc.CorrespondenceDocument.Id).toPromise().then(d => {
          this.refreshDocuments(this.correspondence.Id);
        });
      }
    });
  }

  removeReview(review: ReviewViewModel) {
    this.openDialog(`Are you sure you want to delete review "${(review.ReviewText || "").substr(0, 30)}"?`).then(result => {
      if (result) {
        this.repo.Delete(ReviewViewModel, review.Id).toPromise().then(d => {
          this.refreshReviews(this.correspondence.Id);
        });
      }
    });    
  }

  refresh(id: number) {
    this.editMode = false;
    this.submitAttempted = false;
    this.refreshCorrAndCats(id);
    if (this.auth.hasAnyRole("Shepherd", "Admin", "Intake", "Reviewer")) {
      this.refreshDocuments(id);
    }
    if (this.auth.hasAnyRole("Shepherd", "Admin", "Reviewer")) {
      this.refreshReviews(id);
    }
  }

  refreshCorrAndCats(id: number) {
    this.repo.Get(VMCorr, id)
      .subscribe(corr => {
        if (corr.CorrespondenceCategories)
          this.activeCategories = corr.CorrespondenceCategories.map(c => { return { id: c.Id, text: c.Category.Name }; });
        this.setupCorrForm(corr);
        this.correspondence = corr;
        //this.myForm.setValue(corr);
      });
  }

  refreshDocuments(id: number) {
    let docFilter = new CorDocFilter();
    docFilter.CorrespondenceId = id;
    docFilter.Type = CorDocFilterType.ByCorrespondenceId;
    this.documentsObs = this.repo.Page(VMCorDoc, docFilter).pipe(
      map(cdocs => cdocs.map(cd => {
        if (cd.Document)
          cd.Document.CorrespondenceDocument = cd;
        return cd.Document;
      })));
  }

  refreshReviews(id: number) {
    let filter = new ReviewFilterViewModel();
    filter.CorrespondenceId = id;
    filter.Type = ReviewFilterType.ByCorrespondenceId;
    this.reviewsObs = this.repo.Page(ReviewViewModel, filter);
  }

  setupCorrForm(corr: VMCorr) {
    this.myForm.get("Type").setValue(corr.Type || "Incoming");
    var req = corr.RequestorCorrespondences[0];
    if (req && req.Requestor) {    
      this.myForm.get("RequestorFullName").setValue(req.Requestor.FullName);
      this.myForm.get("RequestorFirstName").setValue(req.Requestor.FirstName);
      this.myForm.get("RequestorMiddleName").setValue(req.Requestor.MiddleName);
      this.myForm.get("RequestorLastName").setValue(req.Requestor.LastName);
      this.myForm.get("RequestorDetails").setValue(req.Requestor.Notes);
      let cd = req.Requestor.ContactDetails[0];
      if (cd) {
        this.myForm.get("RequestorAddress").setValue(cd.Address1);
        this.myForm.get("RequestorCity").setValue(cd.City);
        this.myForm.get("RequestorState").setValue(cd.State);
        this.myForm.get("RequestorZip").setValue(cd.PostalCode);
        this.myForm.get("RequestorPhone").setValue(cd.Phone);
        this.myForm.get("RequestorEmail").setValue(cd.Email);
      }
    }
    this.myForm.get("Title").setValue(corr.Title || "");
    this.myForm.get("Status").setValue(corr.Status);
    this.myForm.get("Notes").setValue(corr.Notes);
    this.myForm.get("Priority").setValue(corr.Priority);
    this.myForm.get("Summary").setValue(corr.Summary || "");
    this.myForm.get("ReceivedThrough").setValue(corr.ReceivedThrough || "");
    this.myForm.get("DateReceived").setValue(moment(corr.DateReceived).format("YYYY-MM-DD") || "");
    this.myForm.get("DueDate").setValue(moment(corr.DueDate).format("YYYY-MM-DD") || "");
    this.myForm.get("Category").setValue(corr.CorrespondenceCategories.map(cc => cc.CategoryId));
  }

  onUpdate(updated: VMCorr) {
    this.correspondence = updated;
    this.setupCorrForm(updated);
    this.editMode = false;
  }

  buildCorrespForm(): FormGroup {
    return this.fb.group({
      Type: ['', Validators.required],
      //RequestorSummary: ['', Validators.required],
      RequestorDetails: [''],
      RequestorFullName: [''],
      RequestorFirstName: [''],
      RequestorMiddleName: [''],
      RequestorLastName: [''],
      RequestorAddress: [''],
      RequestorCity: [''],
      RequestorState: [''],
      RequestorZip: [''],
      RequestorEmail: [''],
      RequestorPhone: [''],

      RequestorDetail: [''],
      Priority: ['', Validators.required],
      Status: ['', Validators.required],
      Title: ['', Validators.required],
      Category: [[], Validators.required],
      Summary: [''],
      ReceivedThrough: [''],
      Notes: [''],
      DateReceived: [
        new Date(), 
        [new BeforeTomorrowValidator() ]
      ],
      DueDate: [
        moment().toISOString()
      ]
    },
    {
      validator: (group) => {
        return group.value.Type == "Incoming" && !(group.value.DateReceived && group.value.DueDate && group.value.ReceivedThrough)
               ? { datesRequired: true }
               : null;
      }
    });
  }

  ngOnInit() { }
}
