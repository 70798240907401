
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, Inject, Injectable, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RepositoryServiceToken, LogServiceToken } from '../../services';
import { IRepositoryService, ILogService } from '../../services/i';
import { UserViewModel, RoleViewModel } from '../../view-models';
import { BaseFilterViewModel, UserFilterViewModel, UserFilterType } from '../../view-models/filters';

@Component({
  selector: 'app-user-chooser',
  templateUrl: './user-chooser.component.html',
  styleUrls: ['./user-chooser.component.css']
})
@Injectable()
export class UserChooserComponent implements OnInit {

  users: UserViewModel[] = [];
  roles: RoleViewModel[] = [];
  filter: UserFilterViewModel = new UserFilterViewModel();
  latestKeywords: string = null;
  latestRole: number = null;
  @Input() keywords: FormControl = new FormControl();
  @Input() userRole: FormControl = new FormControl();
  @Input() form: FormGroup = new FormGroup({ keywords: this.keywords, role: this.userRole });

  // http://blog.angular-university.io/introduction-to-angular-2-forms-template-driven-vs-model-driven/
  // Regarding subscribe and emit: https://scotch.io/tutorials/angular-2-http-requests-with-observables
  // Automapper (if really necessary): https://github.com/loedeman/AutoMapper/wiki
  constructor(
    @Inject(RepositoryServiceToken) private repo: IRepositoryService,
    @Inject(LogServiceToken) private log: ILogService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.keywords.valueChanges.pipe(
      debounceTime(400))
      .subscribe(kw => { this.latestKeywords = kw; this.updateResults(); });
    this.userRole.valueChanges
      .subscribe(role => {
        this.latestRole = role; this.updateResults();
      });
  }

  updateResults() {
    this.filter.Keywords = (this.latestKeywords || "").split(" ");
    if (this.latestRole) {
      this.filter.Type = UserFilterType.RoleAndKeywords;
      this.filter.RoleId = this.latestRole;
    } else {
      this.filter.Type = UserFilterType.AnyRoleAndKeywords;
      this.filter.RoleIds = this.roles.map(r => r.Id);
    }
    this.log.debug(this.filter.RoleIds);
    this.repo.Page(UserViewModel, this.filter)
      .subscribe(value => { this.users = value; });
  }

  chooseUser(user: UserViewModel) {
    this.route.queryParams.subscribe(p => {
      let ret = p["return"] + `?userId=${user.Id}`;
      this.router.navigateByUrl(ret);
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(p => {
      let rolesSubset = p["roles"] || null;
      this.keywords.setValue("");
      var roleFilter = new BaseFilterViewModel();
      roleFilter.PageSize = 50;
      let blank = <any>{};
      blank.Id = null;
      blank.RoleName = "";
      this.repo.Page(RoleViewModel, roleFilter)
        .subscribe(roles => {
          if (rolesSubset)
            this.roles = roles.filter(r => rolesSubset.indexOf(r.RoleName) >= 0);
          else
            this.roles = roles;
          this.roles.unshift(blank);
          this.updateResults();
        });
    });
  }

}