
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, Inject, Injectable, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { RepositoryService, RepositoryServiceToken, LogServiceToken } from '../../services';
import { IRepositoryService, ILogService } from '../../services/i';
import { IDWViewModel as IdwUser, UserViewModel } from '../../view-models';
import { IdwFilterViewModel, IdwFilterType } from '../../view-models/filters';
import { IFilterViewModel } from '../../view-models/filters/i';
import { ConfirmDialog } from '../../dialogs';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  users: IdwUser[] = [];
  filter: IdwFilterViewModel = new IdwFilterViewModel();
  @Input() keywords: FormControl = new FormControl();
  dialogRef: MatDialogRef<ConfirmDialog>;

  // http://blog.angular-university.io/introduction-to-angular-2-forms-template-driven-vs-model-driven/
  // Regarding subscribe and emit: https://scotch.io/tutorials/angular-2-http-requests-with-observables
  // Automapper (if really necessary): https://github.com/loedeman/AutoMapper/wiki
  constructor(
    @Inject(RepositoryServiceToken) private repo: IRepositoryService,
    @Inject(LogServiceToken) private log: ILogService,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.keywords.valueChanges.pipe(
      debounceTime(400))
      .subscribe(keywords => {
        this.filter.Keywords = keywords.split(" ");
        let subscription = this.repo.Page(IdwUser, this.filter)
          .subscribe(value => this.users = value);
      });
  }

  openDialog(message: string): Promise<any> {
    this.dialogRef = this.dialog.open(
      ConfirmDialog, {
      disableClose: false
    });
    this.dialogRef.componentInstance.title = "Confirm Add User";
    this.dialogRef.componentInstance.message = message;

    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
    return this.dialogRef.afterClosed().toPromise();
  }

  addUser(user: IdwUser) {
    this.openDialog(`Add user ${user.First} ${user.Last}?`)
      .then(confirm => {
        if (confirm) {
          let uvm = new UserViewModel();
          uvm.HawkId = user.HawkId;
          uvm.Email = user.Email;
          uvm.First = user.First;
          uvm.Middle = user.Middle;
          uvm.Last = user.Last;
          uvm.Title = user.Title;
          uvm.Department = user.Department;
          this.repo.Create(UserViewModel, user)
            .toPromise()
            .then(u => {
              this.router.navigate(["/users", u.Id]);
            })
            .catch(e => {
              this.log.warn(`Unknown error adding user: ${user.HawkId}`);
            });
        }
      });
  }

  ngOnInit() {
    this.keywords.setValue("");
  }
}
