import { HttpParams } from '@angular/common/http';
import { BaseFilterViewModel } from './base-filter-view-model';

export class RequestorFilterViewModel extends BaseFilterViewModel {
    public Type: RequestorFilterType = RequestorFilterType.Keywords;
    public CorrespondenceId: number;

    public getAsHttpParams(): HttpParams {
        var params = super.getAsHttpParams();
      params = params.append('filter.Type', encodeURIComponent(`${this.Type}`));
        if (this.Type == RequestorFilterType.ByCorrespondenceId)
          params = params.append('filter.CorrespondenceId', ''+this.CorrespondenceId);
        return params;
    }
}

export enum RequestorFilterType {
    /** Search using the Keywords property across all fields (email, hawkId, etc). */
    Keywords = 0x1,

    ByCorrespondenceId = 0x2
}
