import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { endpoint } from '../decorators/endpoint.decorator';
import { ContactDetailViewModel } from './contact-detail-view-model';

@endpoint("reviewcomment")
export class ReviewCommentViewModel extends BaseViewModel {
    public ReviewId: number = 0;
    public Comment: string = "";
}
