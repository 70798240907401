import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { endpoint, mappable } from '../decorators';
import { ReviewCommentViewModel } from './review-comment-view-model';
import { ReviewDocumentViewModel } from './review-document-view-model';
import { UserViewModel } from './user-view-model';

@endpoint("review")
export class ReviewViewModel extends BaseViewModel {
    public UserId: number = 0;
    public CorrespondenceId: number = 0;
    public Primary: boolean = false;
    public ReviewStatus: string = "";
    public ReviewText: string = "";

    /** Instructions from the assigning Shepherd to the Reviewer. */
    public Instructions: string = "";

    public Created: Date = null;

    /** Special field causes email to be sent upon update, if set to true. */
    public Remind: boolean = false;

    @mappable("UserViewModel")
    public User: UserViewModel = null;

    @mappable("ReviewCommentViewModel")
    public ReviewComments: ReviewCommentViewModel[] = null;

    @mappable("ReviewDocumentViewModel")
    public ReviewDocuments: ReviewDocumentViewModel[] = null;
}

export let ReviewStatuses: { key: string, value: string }[] = [
    { key: 'assigned', value: "Assigned" },
    { key: 'in-progress', value: "In progress" },
    { key: 'complete', value: "Complete" }
];
