// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
import { IAppConfig } from './i-app.config';
import { LogService } from '../app/services';

export function QALogServiceFactory() { return new LogService(true, false, true, true); }
export const OAuthConfig = {
    redirectUri: "https://cortrack-test.iowa.uiowa.edu/dashboard",
    clientId: "pres-cortrack-web-cf",
    scope: "test.pres.cortrack.api openid profile uiowa",
    responseType: 'code',
    clearHashAfterLogin: true,
    issuer: "https://auth.esapps-test.iowa.uiowa.edu",
    loginUrl: "https://auth.esapps-test.iowa.uiowa.edu/connect/authorize",
    logoutUrl: "https://auth.esapps-test.iowa.uiowa.edu/connect/endsession"
}

export const environment = {
  production: false,
  qa: true,
  appConfig: <IAppConfig>{
    logFactory: QALogServiceFactory,
    APIBaseURI: "https://cortrack-test.iowa.uiowa.edu/api",
    BaseURI: "https://cortrack-test.iowa.uiowa.edu",
    OAuthConfig: OAuthConfig
  }
};