
import {zip as observableZip, empty as observableEmpty,  Observable } from 'rxjs';

import {mergeMap} from 'rxjs/operators';
import { Component, OnInit, Inject, Injectable, EventEmitter, Input, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment/moment';
import { ToastrService } from 'ngx-toastr';

import { RepositoryServiceToken, MapperServiceToken, AuthenticationServiceToken, LogServiceToken, AuthenticationService } from '../../services';
import { IRepositoryService, IMapperService, IAuthenticationService, ILogService } from '../../services/i';
import { 
  CorrespondenceViewModel as VMCorr, 
  CorrespondenceDocumentViewModel as VMCorDoc, 
  DocumentViewModel,
  DocumentViewModel as VMDoc,
  ReviewViewModel,
  ReviewCommentViewModel,
  ReviewStatuses,
  UserViewModel
} from '../../view-models';
import { 
  BaseFilterViewModel as CorFilter, 
  CorrespondenceDocumentFilterViewModel as CorDocFilter,
  CorDocFilterType
} from '../../view-models/filters';
import { IFilterViewModel } from '../../view-models/filters/i';
import { BeforeTomorrowValidator, AfterTodayValidator } from '../add-correspondence/validators';
import { ConfirmDialog } from '../../dialogs';

@Component({
  selector: 'app-add-review',
  templateUrl: './add-review.component.html',
  styleUrls: ['./add-review.component.css']
})@Injectable()
export class AddReviewComponent implements OnInit {
  editMode: boolean = false;
  showErrors: boolean = false;
  submitAttempted: boolean = false;
  filter: IFilterViewModel = new CorFilter();
  corrObs: Observable<VMCorr>;
  reviewerObs: Observable<UserViewModel>;
  documents: DocumentViewModel[] = [];
  statuses: { key: string, value: string }[] = ReviewStatuses;
  returnRoute: string;
  userSelected: boolean = false;

  @Input() keywords: FormControl = new FormControl();
  @Input() myForm: FormGroup;

  dialogRef: MatDialogRef<ConfirmDialog>;

  // http://blog.angular-university.io/introduction-to-angular-2-forms-template-driven-vs-model-driven/
  // Regarding subscribe and emit: https://scotch.io/tutorials/angular-2-http-requests-with-observables
  // Automapper (if really necessary): https://github.com/loedeman/AutoMapper/wiki
  constructor(
    @Inject(RepositoryServiceToken) private repo: IRepositoryService,
    @Inject(MapperServiceToken) private mapper: IMapperService,
    @Inject(AuthenticationServiceToken) public auth: AuthenticationService,
    @Inject(LogServiceToken) private log: ILogService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private vcr: ViewContainerRef
  ) {
    this.myForm = this.buildForm();


    //this.myForm.disable();

    this.returnRoute = route.snapshot.url.reduce((p, s) => `${p}/${s.path}`, "");
    this.corrObs = this.route.params.pipe(mergeMap(params => {
      let id: number = +params['id'];
      return this.repo.Get(VMCorr, id);
    }));

    this.reviewerObs = this.route.queryParams.pipe(mergeMap(qparams => {
      let id: number = +qparams['userId'];
      if (id > 0) {
        this.userSelected = true;
        return this.repo.Get(UserViewModel, id);
      } else {
        return observableEmpty();
      }
    }));
  }

  buildForm(): FormGroup {
    return this.fb.group({
      review: [''],
      primary: ['', Validators.required],
      instructions: [''],
      status: ['assigned', Validators.required]
    });
  }

  formValid() {
    return this.myForm.valid && this.userSelected;
  }

  ngOnInit() {
  }

  openDialog(message: string): Promise<any> {
    this.dialogRef = this.dialog.open(
      ConfirmDialog, {
      disableClose: false
    });
    this.dialogRef.componentInstance.title = "Confirm";
    this.dialogRef.componentInstance.message = message;
    this.dialogRef.componentInstance.actionBtn = "Yes";
    this.dialogRef.componentInstance.cancelBtn = "No";

    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
    return this.dialogRef.afterClosed().toPromise();
  }

  goToCorrespondence() {
    this.corrObs.subscribe(corr => {
      var text = this.myForm.get("review").value;
      if (text && text.length) {
        this.openDialog("You seem to have unsaved changes. Are you sure you want to cancel?")
          .then(yes => {
            if (yes) {
              this.router.navigate(["/correspondence", corr.Id]);
            }
          })
      } else {
        this.router.navigate(["/correspondence", corr.Id]);
      }
    });
  }

  addReview() {
    this.log.debug(this.corrObs, this.reviewerObs);
    observableZip(this.corrObs, this.reviewerObs)
      .subscribe(values => {    
        let corr = values[0];
        let reviewer = values[1];
        this.log.debug(reviewer, corr);
        if (this.myForm.errors || ! reviewer) {
          this.showErrors = true;
          return;
        }

        let user = this.auth.getUserInfo<UserViewModel>();
        if (user) {
          let rev = new ReviewViewModel();
          rev.Primary = this.myForm.get("primary").value;
          rev.ReviewText = this.myForm.get("review").value;
          rev.ReviewStatus = this.myForm.get("status").value;
          rev.Instructions = this.myForm.get("instructions").value;
          rev.UserId = reviewer.Id;
          rev.CorrespondenceId = corr.Id;
          this.repo.Create(ReviewViewModel, rev)
            .toPromise()
            .then(newRev => {       
              if (rev.Primary) {
                corr.Status = "Assigned";
                this.repo.Update(VMCorr, corr).toPromise()
                  .then(() => { this.success(newRev, corr); });
              } else {
                this.success(newRev, corr);
              }
            });
        } else {
          this.toastr.error("Your session has expired. Please login, again.");
        }
    });
  }

  success(rev: ReviewViewModel, corr: VMCorr) {
    let rt = rev.ReviewText ? rev.ReviewText.trim() : "";
    let ct = corr.Summary ? corr.Summary.trim() : "";
    let message = [
      "Review ",
      rt.length ? `"${rt.substr(0, 20)}${rt.length > 20 ? '...' : ''}" ` : "",
      "has been created",
      ct.length ? ` for "${ct.substr(0, 20)}${ct.length > 20 ? '...' : ''}".` : ".",
    ].join('');
    this.toastr.success(message);
    this.router.navigate(["/correspondence", corr.Id, "reviews", rev.Id]);
  }
}
