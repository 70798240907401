<section id="corr-add-review-container">
    <!-- Sub Header -->
    <app-header title='Add Reviewer'></app-header>
    <!-- Add New Reviewer -->
    <div class="container">
      <section class="info-box">
        <form [formGroup]="myForm">
          <h1 class="info-box-heading">Add Reviewer</h1>
          <div class="info-box-wrapper">
            <div class="info-box-body">
              <fieldset>
                <div class="form-field-container" fxLayout="column">
                  <!-- User -->
                  <div class="form-field-container new-reviewer" fxLayout="row" *ngIf="auth.hasAnyRole('Admin', 'Shepherd')">
                    <div class="new-reviewer-details col-mat-12" fxLayout="column" fxFlex>
                      <h2 class="user" >User &#42;</h2>
                      <p>{{ (reviewerObs | async)?.FullName || "None selected" }}</p>
                    </div>
                    <button type='button' mat-button mat-raised-button [routerLink]="['/choose/user']"
                      [queryParams]="{ return: this.returnRoute, roles: ['Reviewer', 'Admin', 'Shepherd'] }">
                      Select User
                    </button>
                    <mat-hint *ngIf="!(reviewerObs | async) && showErrors" [ngStyle]="{'color': '#f44336'}">
                        Please select categories for this correspondence.
                    </mat-hint>
                  </div>
                  <!-- Primary/FYI Reviewer -->
                  <div class="form-field-container primaryFyi col-mat-12" fxLayout="column" *ngIf="auth.hasAnyRole('Admin', 'Shepherd')">
                    <h2>Reviewer Status &#42;</h2>
                    <mat-radio-group fxLayout="column" formControlName="primary">
                      <mat-radio-button value='true'>Primary Reviewer</mat-radio-button>
                      <mat-radio-button value='false'>FYI Reviewer</mat-radio-button>
                    </mat-radio-group>
                  </div>
                  <!-- Status -->
                  <div class="form-field-container new-reviewer-status col-mat-12" fxLayout="column">
                    <mat-form-field>
                      <mat-select placeholder='Status &#42;' formControlName="status">
                        <mat-option *ngFor="let s of statuses" [value]="s.key">{{ s.value }}</mat-option>
                      </mat-select>                    
                    </mat-form-field>
                    <mat-hint *ngIf="myForm.get('status').errors && showErrors" [ngStyle]="{'color': '#f44336'}">
                        Please choose an initial status for this review.
                    </mat-hint>
                  </div>
                  <!-- Instructions to Reviewer -->
                  <div class="form-field-container col-mat-12" fxLayout="column" *ngIf='auth.hasAnyRole("Shepherd", "Admin")'>
                    <mat-form-field>
                      <textarea matInput placeholder="Instructions to Reviewer" formControlName="instructions"></textarea>
                    </mat-form-field>
                  </div>
                  <!-- Review Text -->
                  <div class="form-field-container col-mat-12" fxLayout="column">
                    <mat-form-field>
                      <textarea matInput placeholder="Review Text" formControlName="review"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
              <button type='button' class="btn-save" mat-button mat-raised-button (click)='addReview()' [disabled]="! formValid()">Add Reviewer</button>
              <button type='button' mat-button mat-raised-button (click)='goToCorrespondence()'>Cancel</button>
            </div>
          </div>
        </form>
      </section>
    </div>
</section>