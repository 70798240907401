import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';

@Directive({
    selector: '[validateAfterToday][formControlName],[validateAfterToday][formControl],[validateAfterToday][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => AfterTodayValidator), multi: true }
    ]
})
export class AfterTodayValidator implements Validator {
    today: moment.Moment = moment().add(-1, "days");
    constructor() {}
    
    validate(c: AbstractControl): { [key: string]: any } {
        let v = moment(c.value, "YYYY-MM-DD");

        // value not equal
        if (v < this.today) return {
            afterToday: true
        }
        return null;
    }
}