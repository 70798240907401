import { HttpParams } from '@angular/common/http';
import { BaseFilterViewModel } from './base-filter-view-model';
import { Operator } from './operator';

export class CorrespondenceDocumentFilterViewModel extends BaseFilterViewModel {
    public Type: CorDocFilterType = CorDocFilterType.ByCorrespondenceId;
    public CorrespondenceId: number = 0;

    public getAsHttpParams(): HttpParams {
        var params = super.getAsHttpParams();
      params = params.append('filter.Type', encodeURIComponent(`${this.Type}`));
      params = params.append('filter.CorrespondenceId', encodeURIComponent(`${this.CorrespondenceId}`));
        return params;
    }
}

export enum CorDocFilterType {
    ByCorrespondenceId = 0x1,
}
