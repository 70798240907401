import { HttpParams } from '@angular/common/http';

const mapMetadataKey = "its.appdev.map.decorator";
export function mappable<T>(type: string)
{
    return (target: Object, propertyKey: string | symbol) => {
        let d = Reflect.get(target, mapMetadataKey) || {};
        d[propertyKey] = type;
        Reflect.set(target, mapMetadataKey, d);
    };
}

export function getMappableProperties<T>(target: Object)
    : { [propKey : string]: string }
{
    return Reflect.get(target, mapMetadataKey) || {};
}
