import { HttpParams } from '@angular/common/http';
import { BaseFilterViewModel } from './base-filter-view-model';
import { Operator } from './operator';

export class ReviewDocumentFilterViewModel extends BaseFilterViewModel {
    public Type: ReviewDocFilterType = ReviewDocFilterType.ByReviewId;
    public ReviewId: number = 0;

    public getAsHttpParams(): HttpParams {
        var params = super.getAsHttpParams();
      params = params.append('filter.Type', encodeURIComponent(`${this.Type}`));
      params = params.append('filter.ReviewId', encodeURIComponent(`${this.ReviewId}`));
        return params;
    }
}

export enum ReviewDocFilterType {
    ByReviewId = 0x1,
}
