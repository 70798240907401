import { HttpParams } from '@angular/common/http';
import { BaseFilterViewModel } from './base-filter-view-model';

export class UserFilterViewModel extends BaseFilterViewModel {
    public Type: UserFilterType = UserFilterType.Keywords;
    public HawkId: string;
    public RoleId: number;
    public RoleIds: number[];

    public getAsHttpParams(): HttpParams {
        var params = super.getAsHttpParams();
      params = params.append('filter.Type', encodeURIComponent(`${this.Type}`));
      params = params.append('filter.HawkId', this.HawkId);
      params = params.append('filter.RoleId', ""+this.RoleId);
        for(var i in this.RoleIds)
          params = params.append('filter.RoleIds', ""+this.RoleIds[i]);
        params = params.delete('filter.pageSize');
        params = params.append('filter.pageSize', '1000');
        return params;

    }
}

export enum UserFilterType {
    /** Search using the Keywords property across all fields (email, hawkId, etc). */
    Keywords = 0x1,

    /** Search using the HawkId field, only. */
    HawkId = 0x2,

    /** Return users matching role (if any) and keywords (if any). */
    RoleAndKeywords = 0x3,

    /** Return users matching any of the provided Roles and keywords. */
    AnyRoleAndKeywords = 0x4
}
