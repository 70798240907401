<div>
  <p>
    If the download does not work, please try again by clicking, below.
  </p>
  <div>
    <a #downloadLink>{{download && download.Filename}}</a>
  </div>
  <p>
    The generated link cannot be shared.
  </p>
</div>
