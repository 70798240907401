import { Component, OnInit, Input, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationServiceToken } from '../../services';
import { IAuthenticationService } from '../../services/i'; 

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() title: string = "";
  showBreadcrumbs: boolean = true;

  constructor(
    private route: ActivatedRoute,
    @Inject(AuthenticationServiceToken) private auth: IAuthenticationService 
  ) {}

  ngOnInit()
  {
    this.showBreadcrumbs = this.auth.isAuthenticated; //this.route.snapshot.url.length > 1;
  }

}
