
import {map, startWith, mergeMap} from 'rxjs/operators';
import { OnInit, Inject, Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable, Observer } from 'rxjs';

import { RepositoryServiceToken, CorrFilterServiceToken } from '../../services';
import { IRepositoryService, ICorrFilterService } from '../../services/i';
import { UserViewModel, RequestorViewModel, CategoryViewModel,
  BaseFilterViewModel, RequestorFilterViewModel, RequestorFilterType, UserFilterViewModel, Statuses } from '../../view-models';
import { receivedThrough } from '../../static-data';
import {
  CorrespondenceFilterViewModel as CorFilter, 
  CorrespondenceSubFilterViewModel as CorSubFilter,
  CorFilterType,
  Operator
} from '../../view-models/filters';

@Component({
  selector: 'filter-dialog',
  templateUrl: './filter.dialog.html',
  styleUrls: ['./filter.dialog.css'],
})
export class FilterDialog implements OnInit {
    constructor(
      public dialogRef: MatDialogRef<FilterDialog>,
      private http: HttpClient,
      private formBuilder: FormBuilder,
      @Inject(RepositoryServiceToken) private repo: IRepositoryService,
      @Inject(CorrFilterServiceToken) private corrFilterSvc: ICorrFilterService
    ) {
      this.savedFilterName = null;
      this.filter = new CorFilter();
      this.filterTypes = CorFilterType;

      this.categoryControl = new FormControl();
      this.keywordsControl = new FormControl();
      this.requestorControl = new FormControl();
      this.reviewerControl = new FormControl();
      this.commTypeControl = new FormControl();
      this.sortControl = new FormControl();
      this.statusControl = new FormControl();
      this.filterName = new FormControl();
      this.saveToFavorites = new FormControl();
      this.filteredCategories = this.categoryControl.valueChanges.pipe(
        startWith(null),
        mergeMap(cat => {
          let catFilter = new BaseFilterViewModel();
          catFilter.PageSize = 100;
          catFilter.Keywords = cat ? cat.split() : [];
          return this.repo.Page(CategoryViewModel, catFilter).pipe(map(cs => cs.map(c => c.Name)));
        }),);
      this.filteredRequestors = this.requestorControl.valueChanges.pipe(
        startWith(null),
        mergeMap(req => {
          let reqFilter = new RequestorFilterViewModel();
          reqFilter.Type = RequestorFilterType.Keywords;
          reqFilter.Keywords = req ? req.split() : [];
          return this.repo.Page(RequestorViewModel, reqFilter);
        }),);
      this.filteredReviewers = this.reviewerControl.valueChanges.pipe(
        startWith(null),
        mergeMap(rev => {
          let revFilter = new BaseFilterViewModel();
          revFilter.Keywords = rev ? rev.split() : [];
          return this.repo.Page(UserViewModel, revFilter);
        }),);

      this.filteredCommTypes = this.commTypeControl.valueChanges.pipe(
        startWith(null),
        map(t => t ? receivedThrough.filter(r => r && new RegExp(t, "gi").test(r)) : receivedThrough),);
    }

    ngOnInit() {
      //this.fetchCategories();
      this.setupRequestorsTypeahead();
      this.setupReviewersTypeahead();
      this.loadFilter();
    }

    loadFilter() {
      let cat = this.getFirstSubFilter(CorFilterType.Category);
      let com = this.getFirstSubFilter(CorFilterType.CommunicationType);
      let req = this.getFirstSubFilter(CorFilterType.Requestor);
      let rev = this.getFirstSubFilter(CorFilterType.Reviewer);
      let status = this.getFirstSubFilter(CorFilterType.Status);
      let kw = this.getFirstSubFilter(CorFilterType.Keywords);
      if(cat) this.categoryControl.setValue(cat.Keywords[0] || "");
      if(com) this.commTypeControl.setValue(com.Keywords[0] || "");
      if(req) this.requestorControl.setValue((req.Keywords || []).join(" "));
      if(rev) this.reviewerControl.setValue((rev.Keywords || []).join(" "));
      if(status) this.statusControl.setValue(status.Keywords[0] || "");
      if(kw) this.keywordsControl.setValue((kw.Keywords || []).join(" "));
      this.sortControl.setValue(this.filter.Ascending ? "true" : "false");
      if (this.savedFilterName) {
        this.saveToFavorites.setValue(true);
        this.filterName.setValue(this.savedFilterName);
      } else {
        this.saveToFavorites.setValue(false);
      }
    }

    getFirstSubFilter(type: CorFilterType) {
      return (this.filter.Filters.filter(f => f.Type == type) || [])[0];
    }

    close() {
      this.dialogRef.close(this.filter);
    }

    saveAndClose() {
      this.filter.Filters = [];
      if (this.categoryControl.value) {
        let subfilter = new CorSubFilter();
        subfilter.Type = CorFilterType.Category;
        subfilter.Op = Operator.ApiEquals;
        subfilter.Keywords = [this.categoryControl.value];
        this.filter.Filters.push(subfilter);
      }
      if (this.commTypeControl.value) {
        let subfilter = new CorSubFilter();
        subfilter.Type = CorFilterType.CommunicationType;
        subfilter.Op = Operator.ApiEquals;
        subfilter.Keywords = [this.commTypeControl.value];
        this.filter.Filters.push(subfilter);
      }
      if (this.requestorControl.value) {
        let subfilter = new CorSubFilter();
        subfilter.Type = CorFilterType.Requestor;
        subfilter.Keywords = this.requestorControl.value.split(/\W+/);
        this.filter.Filters.push(subfilter);
      }
      if (this.reviewerControl.value) {
        let subfilter = new CorSubFilter();
        subfilter.Type = CorFilterType.Reviewer;
        subfilter.Keywords = this.reviewerControl.value.split(/\W+/);
        this.filter.Filters.push(subfilter);
      }
      if (this.statusControl.value) {
        let subfilter = new CorSubFilter();
        subfilter.Type = CorFilterType.Status;
        subfilter.Op = Operator.ApiEquals;
        subfilter.Keywords = [this.statusControl.value];
        this.filter.Filters.push(subfilter);
      }
      if (this.keywordsControl.value) {
        let subfilter = new CorSubFilter();
        subfilter.Type = CorFilterType.Keywords;
        subfilter.Keywords = this.keywordsControl.value.split(/\W+/);
        this.filter.Filters.push(subfilter);
      }
      this.filter.Ascending = this.sortControl.value == "true";
      if (this.saveToFavorites) {
        this.saveFilter();
      }
      this.close();
    }

    saveFilter() {
      this.corrFilterSvc.saveFilter(this.filterName.value, this.filter);
    }

    addCategory() {
      let subfilter = new CorSubFilter();
      subfilter.Type = CorFilterType.Category;
      subfilter.Keywords = [this.categoryControl.value];
      this.filter.Filters.push(subfilter);
      this.categoryControl.reset();
    }

    addKeywords() {
      let subfilter = new CorSubFilter();
      subfilter.Type = CorFilterType.Keywords;
      subfilter.Keywords = [this.keywordsControl.value];
      this.filter.Filters.push(subfilter);
      this.keywordsControl.reset();
    }

    addRequestor() {
      let subfilter = new CorSubFilter();
      subfilter.Type = CorFilterType.Requestor;
      subfilter.Keywords = [this.requestorControl.value];
      this.filter.Filters.push(subfilter);
      this.requestorControl.reset();
    }

    addReviewer() {
      let subfilter = new CorSubFilter();
      subfilter.Type = CorFilterType.Reviewer;
      subfilter.Keywords = [this.reviewerControl.value];
      this.filter.Filters.push(subfilter);
      this.reviewerControl.reset();
    }

    addStatus(status: string) {
      let subfilter;
      for (var i=0; i<this.filter.Filters.length; i++) {
        var f = this.filter.Filters[i];
        if (f.Type == CorFilterType.Status) {
          subfilter = f;
          break;
        }
      }
      if (!subfilter) {
        subfilter = new CorSubFilter();
        subfilter.Type = CorFilterType.Status;
        subfilter.Op = Operator.ApiEquals;
        subfilter.Keywords = [];
        this.filter.Filters.push(subfilter);
      }
      subfilter.Keywords.push(status);
      this.resetStatuses();
    }

    resetStatuses() {
      let statuses = Statuses.map(s => s); // copy
      let ind;
      for (var i=0; i<this.filter.Filters.length; i++) {
        var f = this.filter.Filters[i];
        if (f.Type == CorFilterType.Status && (ind = statuses.indexOf(f.Keywords[0])) >= 0) {
          statuses.splice(ind, 1);
        }
      }

      this.filteredStatuses = statuses;
    }

    setupRequestorsTypeahead() {

      /*this.reqObservable = Observable.create(observer => {
        this.myForm.controls["Requestor"].valueChanges
          .debounceTime(400)
          .subscribe(keywords => {
            var filter = new BaseFilterViewModel();
            filter.Keywords = keywords.split();
            var subscription = this.repo.Page(RequestorViewModel, filter)
              .subscribe(values => {
                let items: CompleterItem[] = values.map(r => { return <CompleterItem>{ title: r.LastFirst }; });
                observer.next(items);
              });
          });
      });
      this.reqDataService = this.completerService.local(this.reqObservable, "title", "title");*/
    }

    setupReviewersTypeahead() {
      /*this.revObservable = Observable.create(observer => {
        this.myForm.controls["Reviewer"].valueChanges
          .debounceTime(400)
          .subscribe(keywords => {
            var filter = new UserFilterViewModel();
            filter.Keywords = keywords.split();
            var subscription = this.repo.Page(UserViewModel, filter)
              .subscribe(values => {
                let items: CompleterItem[] = values.map(r => { return <CompleterItem>{ title: r.LastFirst }; });
                observer.next(items);
              });
          });
      });
      this.revDataService = this.completerService.local(this.revObservable, "title", "title");*/
    }

    savedFilterName: string = null;
    myForm: FormGroup = null;
    categoryControl: FormControl;
    keywordsControl: FormControl;
    requestorControl: FormControl;
    reviewerControl: FormControl;
    statusControl: FormControl;
    sortControl: FormControl;
    filterName: FormControl;
    commTypeControl: FormControl;
    saveToFavorites: FormControl;
    filteredCategories: any;
    filteredRequestors: any;
    filteredReviewers: any;
    filteredStatuses: string[] = Statuses;
    filteredCommTypes: any;
    filter: CorFilter;
    filterTypes: any;

    //reqDataService: CompleterData;
    reqObservable: Observable<RequestorViewModel[]>;
    requestors: any[] = [];

    //revDataService: CompleterData;
    revObservable: Observable<RequestorViewModel[]>;
    reviewers: any[] = [];
    categories: any[] = []; /*[
      {
        CategoryName: 'Outgoing Correspondence',
        CategoryId: 1
      },
      {
        CategoryName: 'Academics',
        categoryId: 2
      },
      {
        
        CategoryName: 'Admissions',
        CategoryId: 3
      },
      {
        CategoryName: 'Alumni/ Fundraising',
        CategoryId: 4
      },
      {
        CategoryName: 'Athletics',
        CategoryId: 5
      }
  ];*/
}
