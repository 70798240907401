import { Directive, HostBinding, ContentChildren, QueryList, Inject, Injectable, ChangeDetectorRef,
  AfterContentInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, RouterLink, RoutesRecognized } from '@angular/router';
import { IRoleService } from '../services/i';
import { RoleServiceToken } from '../services';

@Directive({
  selector: '[secured]'
})
@Injectable()
export class SecuredDirective implements OnChanges, OnDestroy, AfterContentInit {
  @HostBinding("style.display")
  private display: string = "none";
  private routerSubscription: Subscription;

  @ContentChildren(RouterLink, { descendants: true })
  routerLinks:QueryList<RouterLink>;

  constructor(
    @Inject(RoleServiceToken) private roleService: IRoleService,
    private router: Router,
    private cdr: ChangeDetectorRef) {
      this.routerSubscription = this.router.events.subscribe(s => {
        if (s instanceof RoutesRecognized)
          this.update();
      });
  }

  ngAfterContentInit(): void { this.routerLinks.changes.subscribe(_ => this.update()); }
  ngOnChanges(changes: SimpleChanges): void { this.update(); }
  ngOnDestroy(): void { this.routerSubscription.unsubscribe(); };

  private update(): void {
    if (this.routerLinks.length > 1)
      throw new Error("More than one routerLink found within this secured element. Cannot determine whether to hide element.");
    var link = <RouterLink>this.routerLinks.toArray()[0];
    // TODO FIXME: could there be a better way of doing this?
    var path = link.urlTree.toString();
    this.display = this.roleService.canActivate(path, link.queryParams) ? "" : "none";
    this.cdr.detectChanges();
  }
}
