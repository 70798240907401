import { Injectable, Inject } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommunityAuthService } from 'app/services/community-auth.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable } from 'rxjs';
import { OAuthConfig } from 'environments/environment';
import { switchMap, filter, tap } from 'rxjs/operators';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private oauthService: OAuthService,
        private authService: CommunityAuthService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
      ): Observable<boolean> {
        this.oauthService.configure(OAuthConfig);
        //this.oauthService.events.subscribe(e => console.log("event, e", e));
        console.debug(state.url);
        return from(this.authService.runInitialLoginSequence())
            .pipe(
                switchMap(_ => this.authService.isDoneLoading$),
                filter(isDone => isDone),
                switchMap(_ => this.authService.isAuthenticated$),
                tap(isAuth => { if (! isAuth) this.router.navigate(['/help']); })
            );
      }
}
