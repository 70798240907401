<div id="filter-dialog-container" class="info-box">
  <div mat-dialog-title>
    <h1 class="info-box-heading">Filter Correspondence</h1>
  </div>
  <div class="info-box-wrapper">
      <mat-dialog-content class="info-box-body">
        <!-- date -->
        <div fxLayout="row" fxLayoutAlign="center center">
          <h2>Date</h2>
          <mat-button-toggle-group [formControl]="sortControl" fxFlex>
            <mat-button-toggle value="true" class="toggle-left-btn" fxFlex><mat-icon>arrow_upward</mat-icon></mat-button-toggle>
            <mat-button-toggle value="false" fxFlex><mat-icon>arrow_downward</mat-icon></mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <!-- communication type -->
        <div class="filter-container">
          <mat-form-field fxFlex appearance="legacy">
            <input matInput placeholder="Communication Type" [matAutocomplete]="commTypeAuto" [formControl]='commTypeControl'>
          </mat-form-field>
          <mat-autocomplete #commTypeAuto="matAutocomplete">
            <mat-option *ngFor="let type of filteredCommTypes | async" [value]="type">
              {{ type }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <!-- category -->
        <div class="filter-container">
          <mat-form-field fxFlex appearance="legacy">
            <input matInput placeholder="Category" [matAutocomplete]="categoryAuto" [formControl]='categoryControl'>
          </mat-form-field>
          <mat-autocomplete #categoryAuto="matAutocomplete">
            <mat-option *ngFor="let cat of filteredCategories | async" [value]="cat">
              {{ cat }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <!-- contact -->
        <div class="filter-container">
          <mat-form-field fxFlex appearance="legacy">
            <input matInput placeholder="Contact" [matAutocomplete]="requestorAuto" [formControl]='requestorControl'>
          </mat-form-field>
          <mat-autocomplete #requestorAuto="matAutocomplete">
            <mat-option *ngFor="let req of filteredRequestors | async" [value]="req.FullName">
              {{ req.FullName }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <!-- reviewer -->
        <div class="filter-container">
          <mat-form-field fxFlex appearance="legacy">
            <input matInput placeholder="Reviewer" [matAutocomplete]="reviewerAuto" [formControl]='reviewerControl'>
          </mat-form-field>
          <mat-autocomplete #reviewerAuto="matAutocomplete">
            <mat-option *ngFor="let rev of filteredReviewers | async" [value]="rev.FullName">
              {{ rev.FullName }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <!-- status -->
        <div class="filter-container">
          <mat-form-field fxFlex appearance="legacy">
            <input matInput placeholder="Status" [matAutocomplete]="statusAuto" [formControl]='statusControl'>
          </mat-form-field>
          <mat-autocomplete #statusAuto="matAutocomplete">
            <mat-option *ngFor="let status of filteredStatuses" [value]="status">
              {{ status }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <!-- keyword -->
        <div class="filter-container">
          <mat-form-field fxFlex appearance="legacy">
            <input matInput type='text' placeholder='Keywords' [formControl]="keywordsControl" />
          </mat-form-field>
        </div>          
        <!-- save filter -->
        <div class="filter-container" fxLayout="column">
          <mat-checkbox fxFlex [formControl]='saveToFavorites' appearance="legacy" class="saveToFavs">Save to Favorites</mat-checkbox>
          <mat-form-field fxFlex appearance="legacy">
              <input matInput [attr.disabled]="!saveToFavorites.value ? '' : null" placeholder="Filter Name" [formControl]='filterName'>
          </mat-form-field>
        </div>
      </mat-dialog-content>
      <mat-dialog-actions class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
        <button class="btn-save" mat-button mat-raised-button (click)="saveAndClose()">Apply</button>
        <button mat-button mat-raised-button (click)="close()">Cancel</button>
      </mat-dialog-actions>
  </div>
</div>