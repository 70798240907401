import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { RoleViewModel, UserViewModel, UserRoleViewModel, BaseFilterViewModel } from '../../view-models';
import { RepositoryServiceToken, LogServiceToken} from '../../services';
import { IRepositoryService, ILogService } from '../../services/i';
import { ConfirmDialog } from '../../dialogs';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
// Purpose: show (and edit) user details not visible in the list view.
// e.g. change user roles.
export class UserDetailsComponent implements OnInit {
  roles: RoleViewModel[];
  allRoles: RoleViewModel[];
  user: UserViewModel;
  userRoleControl: FormControl;
  dialogRef: MatDialogRef<ConfirmDialog>;

  constructor(
    @Inject(RepositoryServiceToken) private repo: IRepositoryService,
    @Inject(LogServiceToken) private log: ILogService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.userRoleControl = new FormControl();
  }

  ngOnInit() {
    //this.keywords.setValue("");
    var roleFilter = new BaseFilterViewModel();
    roleFilter.PageSize = 50;
    this.repo.Page(RoleViewModel, roleFilter)
      .subscribe(roles => {
        this.allRoles = roles;
        this.roles = roles;
      });

    this.route.params.subscribe(params => {
      let id: number = +params['id'];
      this.refreshUser(id);
    });
  }

  openDialog(message: string): Promise<any> {
    this.dialogRef = this.dialog.open(
      ConfirmDialog, {
      disableClose: false
    });
    this.dialogRef.componentInstance.title = "Confirm Removal";
    this.dialogRef.componentInstance.message = message;

    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
    return this.dialogRef.afterClosed().toPromise();
  }

  refreshUser(id: number) {
    this.repo.Get(UserViewModel, id)
      .toPromise()
      .then(user => {
        this.user = user;
        this.roles = this.allRoles.filter(r => user.UserRoles.find(ur => ur.RoleId == r.Id) == null)
      });
  }

  removeUser() {
    this.openDialog(`Are you sure you want to remove ${this.user.FullName}?`)
      .then(confirm => {
        if (confirm) {
          this.repo.Delete(UserViewModel, this.user.Id)
            .toPromise()
            .then(r => {
              this.router.navigate(["/users"]);
            })
            .catch(e => {
              this.log.warn("Error: ", e);
            });
        }
      });
  }

  removeRole(userRole: UserRoleViewModel) {
    this.openDialog(`Remove role ${userRole.Role.RoleName} from ${this.user.FullName}?`)
      .then(confirm => {
        if (confirm) {
          this.repo.Delete(UserRoleViewModel, userRole.Id)
            .toPromise()
            .then(ur => {
              this.refreshUser(this.user.Id);
          });
        }
      });
  }

  addRole() {
    let ur = new UserRoleViewModel();
    ur.UserId = this.user.Id;
    ur.RoleId = this.userRoleControl.value;
    if (! ur.RoleId)
      return;
      
    this.repo.Create(UserRoleViewModel, ur)
      .toPromise()
      .then(r => {
        this.refreshUser(this.user.Id);
      });
  }

}
