<section id="dashboard-container">
  <!-- Sub Header -->
  <app-header title='Correspondences'>
    <!--  Filter -->
    <mat-toolbar class="filter-toolbar">
        <!--<mat-toolbar-row>-->
          <div class="filter-toolbar-heading" fxLayout="row" fxLayoutAlign="start center" (click)='openDialog()'>
            <button mat-raised-button mat-icon-button>
              <mat-icon>filter_list</mat-icon>
            </button>
            <h2>Filter</h2>
          </div>
          <app-filter-viewer [filter]='filter' (updated)='refreshFilter($event)'></app-filter-viewer>
        <!--</mat-toolbar-row>-->
    </mat-toolbar>
  </app-header>
  <!-- Correspondences -->
  <div *ngIf="noAccessDashboard">
    <h2>Sorry, you do not have access to the dashboard. Please consult an administrator of this system.</h2>
  </div>
  <ng-container *ngIf='corrObs | async as corrs'>
      <mat-list class="container block-list">
        <mat-list-item
          *ngFor="let corr of corrs; let i=index;"
          [routerLink]="['/correspondence', corr.Id]"
          [ngClass]="{'unopened': corr.Status === 'Unopened'}">
            <div class="corr-list-heading" fxLayout="row" fxLayoutAlign="space-between center">
              <h2 matSubheader>{{ corr.DateReceived | amDateFormat:'MMM DD' }}</h2>
              <div class="element">{{corr.Status || "Status unavailable"}}</div>
            </div>
            <mat-divider style="position: relative;"></mat-divider>
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="column">
                <h3 matLine>{{ corr.RequestorName }}</h3>
                <p matLine>{{ corr.Title }}</p>
                <p class="corr-list-summary" matLine>{{ corr.Summary?.substr(0, 105) }}{{ corr.Summary?.length > 105 ? "..." : ""}}</p>
              </div>
              <mat-icon>chevron_right</mat-icon>
            </div>
        </mat-list-item>
      </mat-list>
      <div class="list-paginator">
        <mat-paginator
          class="container"
          [length]="totalItems"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="setPage($event)">
        </mat-paginator>
      </div>
    </ng-container>
</section>
