import { HttpParams } from '@angular/common/http';
import { BaseFilterViewModel } from './base-filter-view-model';
import { Operator } from './operator';

export class CorrespondenceSubFilterViewModel extends BaseFilterViewModel {
    public Type: CorFilterType = CorFilterType.Keywords;
    public Op: Operator = Operator.ApiContains;
    
    public Keywords: string[] = [];
    public DateTime: Date = null;
    public EndDateTime: Date = null;

    public getAsHttpParams(): HttpParams {
        throw new Error("This filter isn't meant to be used directly.");
    }
}

export enum CorFilterType {
    /** Search using the Keywords property across all IDW fields (email, hawkId, etc). */
    Keywords = 0x1,

    /** Date the correspondence was received. */
    DateReceived = 0x2,

    /** Category of the correspondence */
    Category = 0x3,

    /** A.k.a contact, the person with whom we're corresponding. */
    Requestor = 0x4,

    Status = 0x5,

    Reviewer = 0x6,

    CommunicationType = 0x7
}
