import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { endpoint } from '../decorators/endpoint.decorator';

@endpoint("population")
export class IDWViewModel extends BaseViewModel {
    public HawkId: string = "";
    public Title: string = "";
    public Department: string = "";
    public Email: string = "";
    public First: string = "";
    public Middle: string = "";
    public Last: string = "";
    public get LastFirst(): string {
        return `${this.Last}, ${this.First} ${this.Middle || ""}`;
    }
}
