import { Component, OnInit, Inject, Injectable, Input, ViewChild } from '@angular/core';
import { VIRTUAL_SCROLL_STRATEGY, FixedSizeVirtualScrollStrategy, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CorrespondenceViewModel as VMCorr,
  CorrCountViewModel} from '../../view-models';
import { CorrespondenceFilterViewModel as CorFilter, CorrCountFilterViewModel } from '../../view-models/filters';


import { FilterDialog } from './filter.dialog';
import { IMapperService, IRepositoryService, IAuthenticationService, ILogService, ICorrFilterService } from '../../services/i';
import { MapperServiceToken, RepositoryServiceToken, AuthenticationServiceToken, LogServiceToken, CorrFilterServiceToken, AuthenticationService } from '../../services';
import { Observable, Subscriber } from 'rxjs';
import { PageEvent} from '@angular/material/paginator';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [{provide: VIRTUAL_SCROLL_STRATEGY, useValue: new FixedSizeVirtualScrollStrategy(50, null, null) }]
})
@Injectable()
export class DashboardComponent implements OnInit {
  dialogRef: MatDialogRef<FilterDialog>;
  @Input() savedFilterName: string;
  @Input() filter: CorFilter;
  correspondences: VMCorr[] = [];
  corrObs: Observable<VMCorr[]>;
  corrObs$: Subscriber<VMCorr[]>;
  noAccessDashboard: boolean;
  loading: boolean = false;
  limit: number = 10;
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;
  page: PageEvent;
  totalItems: number = 0;

  constructor(
    public dialog: MatDialog,
    @Inject(RepositoryServiceToken) private repo: IRepositoryService,
    @Inject(AuthenticationServiceToken) private auth: AuthenticationService,
    @Inject(LogServiceToken) private log: ILogService,
    @Inject(CorrFilterServiceToken) private corrFilterSvc: ICorrFilterService,
    @Inject(MapperServiceToken) private mapper: IMapperService) {
      this.corrObs = new Observable((subscriber) => { this.corrObs$ = subscriber; });
  }


  ngOnInit() {
    this.noAccessDashboard = !this.auth.hasAnyRole("Shepherd", "Reviewer", "Intake", "Admin");
    this.filter = this.rememberFilter();
    this.refreshPaging();
    this.refreshCorrespondences();
  }


  rememberFilter(filter?: CorFilter): CorFilter {
    let storageKey = "edu.uiowa.ITS.CorTrack.filter";
    if (filter) {
      localStorage[storageKey] = JSON.stringify(filter);
      this.filter = filter;
    }

    if (this.filter) {
      return this.filter;
    } // passed in as input

    try {
      let json = localStorage[storageKey];
      let f = JSON.parse(json);
      return this.mapper.MapJsonToVM(CorFilter, f);
    } catch(err) {
      return new CorFilter();
    }
  }

  refreshPaging() {
    this.filter.Page = 0;
    this.filter.PageSize = this.limit;
    let cfilter = new CorrCountFilterViewModel();
    cfilter.Filters = [ this.filter ];
    this.repo.Page(CorrCountViewModel, cfilter)
        .toPromise()
        .then(c => { this.totalItems = c[0].Count; });
  }

  refreshFilter(filter: CorFilter): void {
    this.rememberFilter(filter);
    this.refreshPaging();
    this.refreshCorrespondences();
  }

  openDialog(): void {
    this.dialogRef = this.dialog.open(FilterDialog, {
      disableClose: false
    });
    this.dialogRef.componentInstance.filter = this.mapper.MapJsonToVM(CorFilter, this.filter);
    this.dialogRef.componentInstance.savedFilterName = this.savedFilterName;
    this.dialogRef
      .afterClosed()
      .toPromise()
      .then(result => {
        this.refreshFilter(result);
        this.refreshPaging();
        this.dialogRef = null;
      });
  }

  refreshCorrespondences() {
    if (this.loading)
      return;

    this.loading = true;
    this.repo
        .Page(VMCorr, this.filter)
        .toPromise()
        .then(chunk => {
          this.loading = false;
          return chunk;
        })
        .then(chunk => {
          this.corrObs$.next(chunk);
          this.correspondences = this.correspondences.concat(chunk);
        });
  }

  setPage($event: PageEvent) {
    this.filter.Page = $event.pageIndex;
    this.filter.PageSize = $event.pageSize;
    this.refreshCorrespondences();
  }
}
