<section id="corr-add-document-container">
    <app-header [title]="computedTitle"></app-header>
    <!-- Add New Documnent -->
    <div class="container">
        <section class="info-box">
            <h1 class="info-box-heading">Documents</h1>
            <div class="info-box-wrapper">
                <div class="info-box-body">
                    <form style="margin-bottom: 30px;" #fileChooserForm>
                        <input type="file" #fileInput (change)="fileChangeEvent($event)" multiple [disabled]="disabled" style='display: none;'/>
                        <button mat-button mat-raised-button (click)='clickActualButtonLOL()'>Choose Files...</button>
                    </form>
                    <mat-list class="info-box-list" fxLayout="column">
                        <mat-list-item *ngFor="let doc of documents; let i=index;">
                            <mat-checkbox checked='doc.selected' (click)="toggleFile(i)" [disabled]="disabled"></mat-checkbox>
                            <i class="fa fa-3x" [ngClass]="doc.iconRef" aria-hidden="true"></i> 
                            <h2>{{ doc.name }} -  {{ doc.size | prettySize }}</h2>
                            <button mat-icon-button mat-raised-button (click)="removeFileFromUpload(doc)" [disabled]="disabled">
                                <mat-icon class="mat-icon-dark">remove</mat-icon>
                            </button>
                        </mat-list-item>
                    </mat-list>
                    <div style="margin-top: 30px" *ngIf="documents.length > 0" [ngClass]="{ color: size >= maxRequestLength ? '#f44336' : 'inherit' }">
                        <p *ngIf="size >= maxRequestLength" style='color:#f44336; margin-bottom: 10px;'>*must be less than {{ maxRequestLength | prettySize }}</p>
                        <p>Total size: {{ size | prettySize }}</p>
                    </div>
                </div>
                <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
                    <button mat-button mat-raised-button (click)='uploadFiles()' [disabled]="disabled">Upload Selected</button>
                    <button mat-button mat-raised-button [routerLink]="['../..']" [disabled]="disabled">Cancel</button>
                </div>
                <div class='overlay-and-progress' *ngIf="disabled">
                    <div class='bar-holder'>
                        <mat-progress-bar mode="determinate" [value]="percentComplete"></mat-progress-bar>
                        <div class='progress-message'>
                            Upload {{ percentCompleteDisplay }} complete.
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</section>