import { map } from 'rxjs/operators';
import { Injectable, Inject, InjectionToken } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { getEndpoint } from '../decorators';
import { BaseViewModel } from '../view-models';
import { IFilterViewModel } from '../view-models/filters/i';
import * as Rx from 'rxjs';

import { APIBaseURI } from '../app.config.tokens';
import { AuthenticationService, AuthenticationServiceToken } from './auth.service';
import { ErrorServiceToken } from './error.service';
import { MapperServiceToken } from './mapper.service';
import { IAuthenticationService, IRepositoryService, IMapperService,
  IErrorService } from './i';
import {Observable} from 'rxjs';

@Injectable()
export class RepositoryService implements IRepositoryService {

  constructor(
    private http: HttpClient,
    @Inject(ErrorServiceToken) private errors: IErrorService,
    @Inject(MapperServiceToken) private mapper: IMapperService,
    @Inject(AuthenticationServiceToken) private authService: AuthenticationService,
    @Inject(APIBaseURI) private baseUri: string) { }

  /** @inheritdoc */
  public Page<T extends BaseViewModel>(t: { new (): T }, filter: IFilterViewModel): Rx.Observable<T[]> {
    let uri = `${this.baseUri}/${getEndpoint(t)}/search`;
    let params = filter.getAsHttpParams();
    let headers = new HttpHeaders();
    headers = headers.append('Accept', this.authService.getAccept())
        .append('Authorization', 'Bearer ' + this.authService.getToken());
    return this.http.get<T[]>(uri, { headers: headers, params: params }).pipe(
      map((res: any) => this.mapper.MapJsonToVMArray(t, res as number), this))
  }

  /** @inheritdoc */
  public Get<T extends BaseViewModel>(t: { new (): T }, id: number): Rx.Observable<T> {
    const uri = `${this.baseUri}/${getEndpoint(t)}/${id}`;
    if (id === 0) {
      return Rx.Observable.throw("Database ids must be >= 1.");
    }
      let headers = new HttpHeaders();
    const myHeaders = headers.append('Accept', this.authService.getAccept())
        .append('Authorization', 'Bearer ' + this.authService.getToken());
    return this.http.get<T>(uri, { headers: myHeaders })
          .pipe(
              map((res: any) => this.mapper.MapJsonToVM(t, res as any), this));
  }

  /** @inheritdoc **/
  public Lookup<T extends BaseViewModel>(t: { new (): T }, key: string): Rx.Observable<T> {
    const uri = `${this.baseUri}/${getEndpoint(t)}/bykey/${key}`;
    if (! key) {
      return Rx.Observable.throw("Database ids must be >= 1.");
    }

    let headers = new HttpHeaders();
    const myHeaders = headers.append('Accept', this.authService.getAccept())
        .append('Authorization', 'Bearer ' + this.authService.getToken());
    return this.http.get<T>(uri, { headers: myHeaders })
          .pipe(
              map((res: any) => this.mapper.MapJsonToVM(t, res as any), this));
  }

  /** @inheritdoc */
  public Update<T extends BaseViewModel>(t: { new (): T }, input: T): Rx.Observable<T> {
    const uri = `${this.baseUri}/${getEndpoint(t)}`;
    if (input.Id === 0) {
      return Rx.Observable.throw("Database ids must be >= 1.");
    }
      let headers = new HttpHeaders();
    const myHeaders = headers.append('Accept', this.authService.getAccept())
        .append('Authorization', 'Bearer ' + this.authService.getToken());
      return this.http.put<T>(uri, input, { headers: myHeaders })
          .pipe(
              map((res: any) => this.mapper.MapJsonToVM(t, res as any), this));
    }

  /** @inheritdoc */
  public Create<T extends BaseViewModel>(t: { new (): T }, input: T): Rx.Observable<T> {
    let uri = `${this.baseUri}/${getEndpoint(t)}`;
    let headers = new HttpHeaders();
    const myHeaders = headers.append('Accept', this.authService.getAccept())
        .append('Authorization', 'Bearer ' + this.authService.getToken());
    if (input instanceof t && input.Id !== 0) {
      return Rx.Observable.throw(`Cannot create ${t.name} with pre-existing id ${input.Id}. Did you mean to update?`);
    }
    return this.http.post<T>(uri, input, { headers: myHeaders })
        .pipe(
        map((res: any) => this.mapper.MapJsonToVM(t, res as any), this));
  }

  public CreateMany<T extends BaseViewModel>(t: { new (): T }, input: T[] | FormData): Rx.Observable<T[]> {
    let uri = `${this.baseUri}/${getEndpoint(t)}`;
    let headers = new HttpHeaders();
    // if (input.Id !== 0) {
    //   return Rx.Observable.throw(`Cannot create ${t.name} with pre-existing id ${input.Id}. Did you mean to update?`);
    // }
    const myHeaders = headers.append('Accept', this.authService.getAccept())
        .append('Authorization', 'Bearer ' + this.authService.getToken());
    return this.http.post<T[]>(uri, input, { headers: myHeaders }).pipe(
        map((res: any) => this.mapper.MapJsonToVMArray(t, res as any), this));
  }

  /** @inheritdoc */
  public Delete<T extends BaseViewModel>(t: { new (): T }, id: number): Rx.Observable<T> {
    const uri = `${this.baseUri}/${getEndpoint(t)}/${id}`;
    if (id === 0) {
      return Rx.Observable.throw(`Database ids must be >= 1.`);
    }
    let headers = new HttpHeaders();
    const myHeaders = headers.append('Accept', this.authService.getAccept())
        .append('Authorization', 'Bearer ' + this.authService.getToken());
    return this.http.delete<T>(uri, { headers: myHeaders }).pipe(
        map((res: any) => this.mapper.MapJsonToVM(t, res as any), this));
  }
}

export let RepositoryServiceToken = new InjectionToken("IRepositoryServiceToken");
