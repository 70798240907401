import { Component, OnInit, Inject, ViewChild, ElementRef, InjectionToken } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, Validator } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { RepositoryServiceToken, MapperServiceToken, ProgressServiceToken,
  ErrorServiceToken, LogServiceToken,
  ProgressService } from '../../services';
import { IRepositoryService, IMapperService, IErrorService, ILogService } from '../../services/i';
import {
  DocumentViewModel, CorrespondenceViewModel,
  ReviewViewModel,
  ReviewDocumentViewModel,
  CorrespondenceInputModel as CorrespondenceInputModel,
  CorrespondenceDocumentViewModel
} from '../../view-models';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['./add-document.component.css']
})
export class AddDocumentComponent implements OnInit {
  computedTitle: string;
  whoAmI: InjectionToken<any> = new InjectionToken("AddDocumentComponent");
  mode: string = "correspondence";
  get Id(): number { return this.mode == "correspondence" ? this.cvm.Id : this.rvm.Id; }
  cvm: CorrespondenceViewModel;
  rvm: ReviewViewModel;
  documents: File[] = [];
  maxRequestLength: number = 30*(1<<20);
  size: number = 0;
  disabled: boolean = false;
  percentComplete: number = 0;
  percentCompleteDisplay: string = "0%";
  @ViewChild("fileChooserForm") chooser: ElementRef;
  @ViewChild("fileInput") fileInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(ErrorServiceToken) private errors: IErrorService,
    @Inject(ProgressServiceToken) private progress: ProgressService,
    @Inject(MapperServiceToken) private mapper: IMapperService,
    @Inject(RepositoryServiceToken) private repo: IRepositoryService,
    @Inject(LogServiceToken) private log: ILogService,
  ) {
    this.computedTitle = 
      'Add Documents to '
      + (this.mode == "correspondence" ? "Correspondence" : "Review");
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      let id: number = +params['id'] || +params['cid'];
      let rid: number = +params['rid'];
      if (id) {
        this.repo.Get(CorrespondenceViewModel, id)
          .toPromise()
          .then(corr => {
            this.cvm = corr
            if (this.mode == "correspondence" && corr && corr.Title)
              this.computedTitle += ` "${corr.Title.substr(0, 30).trim()}..."`;
          });
        if (rid) {
            this.mode = "review";
            this.repo.Get(ReviewViewModel, rid)
              .toPromise()
              .then(r => { 
                this.rvm = r;
                if (this.mode != "correspondence" && r && r.ReviewText)
                  this.computedTitle += ` "${r.ReviewText.substr(0, 30).trim()}..."`;
              });
        }
      } else {
        this.errors.broadcast("Error", this.whoAmI, "Internal application error. Please report.");
      }
    });

    this.progress.subscribe((e: ProgressEvent) => {
      this.log.log("Progress", e.loaded, e.total, e);
      if (e.total > 0) {
        this.percentComplete = e.loaded / e.total * 100;
        let p = "" + (Math.round(this.percentComplete * 10) / 10);
        let i = p.indexOf(".");
        this.percentCompleteDisplay = i > -1 ? p.substr(0, i + 2) : p;
      }
    }, e => {
      this.disabled = false;
      this.errors.broadcast("Error", this.whoAmI, "Unknown error during upload. Please retry or contact support.");
    });
  }

  fontRefs = {
    "image/png" : "fa-file-picture-o",
    "image/gif" : "fa-file-picture-o",
    "image/jpeg" : "fa-file-picture-o",
    "application/pdf": "fa-file-pdf-o",
    "application/excel": "fa-file-excel-o",
    "application/msword": "fa-file-word-o",
    "text/plain": "fa-file-text"
  };
  fileChangeEvent($event) {
    var files = <FileList>$event.target.files;
    for (var i=0; i<files.length; i++) {
      if (! this.fontRefs[files[i].type]) {
        this.log.warn("Could not find filetype: " + files[i].type, files[i]);
        files[i]["iconRef"] = "fa-file-o";
      } else {
        files[i]["iconRef"] = this.fontRefs[files[i].type];
      }
      files[i]["selected"] = true;
      this.documents.push(files[i]);
    }
    this.updateMetrics();
    this.chooser.nativeElement.reset();
  }

  updateMetrics() {
    this.size = this.documents
      .filter((d: File) => d["selected"])
      .reduce((s: number, d: File) => d["selected"] ? d.size + s : s, 0);
  }

  removeFileFromUpload(file: File) {
    var i = this.documents.indexOf(file);
    this.documents.splice(i, 1);
    this.updateMetrics();
  }

  clearUnselectedFromUpload() {
    let tempDocs = [];
    for(var i=0; i<this.documents.length; i++) {
      if (this.documents[i]["selected"])
        tempDocs.push(this.documents[i]);
    }
    this.documents = tempDocs;
    this.updateMetrics();
  }

  toggleFile(i: number) {
    let doc = this.documents[i];
    doc["selected"] = !doc["selected"];
    this.updateMetrics();
  }

  clickActualButtonLOL() {
    let event = new MouseEvent('click', {bubbles: true});
    this.fileInput.nativeElement.dispatchEvent(event);
  }

  uploadFiles() {
    this.clearUnselectedFromUpload();
    if (this.documents.length == 0 || this.size > this.maxRequestLength)
      return;

    this.disabled = true;
    let self = this;
    
    let count = 0;
    var form = this.documents.reduce(
      (form: FormData, cur: File) => {
        count = count + 1;
        form.append("files", cur, cur.name);
        return form;
      }, new FormData());
    let promise = self.repo.CreateMany(DocumentViewModel, form).toPromise();
    promise.catch(e => {
      this.disabled = false;
      this.errors.broadcast("Error", this.whoAmI, "Unknown error during upload. Please retry or contact support.");
    });
    if (this.mode == "correspondence") {
      promise.then(
        (docs: DocumentViewModel[]) => {
          let vms = docs.map((d) => this.mapper.MapJsonToVM(CorrespondenceDocumentViewModel, { CorrespondenceId: this.cvm.Id, DocumentId: d.Id }));
            return this.repo.CreateMany(CorrespondenceDocumentViewModel, vms).toPromise();
        })
      .then(
        (cdvms: CorrespondenceDocumentViewModel[] | ReviewViewModel[]) => {
          this.router.navigate(["/correspondence", this.cvm.Id]);
        }
      )
      .catch(e => {
        this.disabled = false;
        this.errors.broadcast("Error", this.whoAmI, "Unknown error during upload. Please retry or contact support.");
      })
    } else if (this.mode == "review") {
      promise.then(
        (docs: DocumentViewModel[]) => {
          this.log.debug(this.rvm);
          let vms = docs.map((d) => this.mapper.MapJsonToVM(
            ReviewDocumentViewModel, 
            <ReviewDocumentViewModel>{ ReviewId: this.rvm.Id, DocumentId: d.Id }));
          return this.repo.CreateMany(ReviewDocumentViewModel, vms).toPromise();
        })
      .then(
        (cdvms: ReviewDocumentViewModel[]) => {
          this.router.navigate(["/correspondence", this.cvm.Id, "reviews", this.rvm.Id]);
        }
      ).catch(e => { 
        this.disabled = false;
        this.errors.broadcast("Error", this.whoAmI, "Unknown error during upload. Please retry or contact support.");
      });
    }
  }

}
