
import {filter} from 'rxjs/operators';
import { Injectable, InjectionToken } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { IErrorService, IErrorMessage, ErrorMessageCallback } from './i';


@Injectable()
export class ErrorService implements IErrorService {
    private handler = new Subject<IErrorMessage>();
    constructor() { }

    public broadcast(type: string, origin: InjectionToken<any>, message: string) {
        let packet: IErrorMessage = { type, origin, message };
        this.handler.next(packet);
    }

    public subscribe(type: string, origin: InjectionToken<any>, callback: ErrorMessageCallback): Subscription {
        return this.handler.pipe(
            filter(message => message.type === type && message.origin === origin))
            .subscribe((msg: IErrorMessage) => callback(msg.type, msg));
    }

    public subscribeAll(callback: ErrorMessageCallback): Subscription {
        return this.handler
            .subscribe((msg: IErrorMessage) => callback(msg.type, msg));    
    }
}

export let ErrorServiceToken = new InjectionToken("IErrorService");
