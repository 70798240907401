import { Component, OnInit, Inject, Injectable, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RepositoryServiceToken, MapperServiceToken } from '../../services';
import { IRepositoryService, IMapperService } from '../../services/i';
import { 
  CorrespondenceViewModel as VMCorr, 
  CorrespondenceDocumentViewModel as VMCorDoc, 
  DocumentViewModel,
  DocumentViewModel as VMDoc } from '../../view-models';
import { 
  BaseFilterViewModel as CorFilter, 
  CorrespondenceDocumentFilterViewModel as CorDocFilter,
  CorDocFilterType
} from '../../view-models/filters';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
@Injectable()
export class DownloadComponent implements OnInit {
  @ViewChild("downloadLink") downloadLink: ElementRef;
  @Input("id") id: number;
  download: DocumentViewModel;
  url: string;

  constructor(
    @Inject(RepositoryServiceToken) private repo: IRepositoryService,
    @Inject(MapperServiceToken) private mapper: IMapperService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

      this.repo.Get(DocumentViewModel, this.id).toPromise().then(doc => {
        this.download = doc;
        let bytes = atob(doc.DocumentFile.ContentBytes);
        let nums = new Array(bytes.length);
        for(var i=0; i<bytes.length; i++) nums[i] = bytes.charCodeAt(i);
        let byteArray = new Uint8Array(nums);
        let blob = new Blob([byteArray], {type: doc.ContentType});
        if (! window.navigator.msSaveOrOpenBlob) {
          let url = URL.createObjectURL(blob);
          this.downloadLink.nativeElement.innerText = doc.Filename;
          this.downloadLink.nativeElement.href = url;
          this.downloadLink.nativeElement.download = doc.Filename;
          this.downloadLink.nativeElement.addEventListener('click', function(ev: Event) {
            ev.cancelBubble = true;
            ev.stopPropagation();
            return false;
          }, true);
          this.downloadLink.nativeElement.click();
        } else {
          window.navigator.msSaveOrOpenBlob(blob, doc.Filename);
        }
    });
  }

}
