import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ProgressServiceToken, ProgressService } from '../services';

@Injectable()
export class ProgressInterceptor implements HttpInterceptor {
  constructor(
      @Inject(ProgressServiceToken) private service:ProgressService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req)
      .pipe(
        tap(
          event => this.service.progressEventObservable.next(event),
          event => this.service.progressEventObservable.error(event)
        )
      
      // finalize when observable
      // finalize(() => { })
      );
  }

  /*build(): any {
    let xhr = super.build();
    xhr.upload.onprogress = (event) => {
        this.service.progressEventObservable.next(event);
    };
    xhr.upload.onerror = (event) => {
        this.service.progressEventObservable.error(event);
    };
    return <any>(xhr); 
  } */
}
