import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { DocumentViewModel } from './document-view-model';
import { CorrespondenceViewModel } from './correspondence-view-model';
import { endpoint, mappable } from '../decorators';

@endpoint("CorrespondenceDocument")
export class CorrespondenceDocumentViewModel extends BaseViewModel {
    public CorrespondenceId: number = 0;
    public DocumentId: number = 0;

    @mappable("DocumentViewModel")
    public Document: DocumentViewModel = null;

    @mappable("CorrespondenceViewModel")
    public Correspondence: CorrespondenceViewModel = null;
}
