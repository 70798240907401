import { Injectable, Inject, InjectionToken } from '@angular/core';
import { Subject, Subscription, Subscriber, Observable } from 'rxjs';

import { CorrespondenceFilterViewModel as CorFilter, CorrespondenceSubFilterViewModel as CorSubFilter, CorFilterType, CorrespondenceFilterViewModel } from '../view-models/filters';
import { IMapperService } from './i';
import { MapperServiceToken } from './mapper.service';

import { ICorrFilterService } from './i';
import { APIBaseURI } from '../app.config.tokens';

@Injectable()
export class CorrFilterService implements ICorrFilterService {
  private updates$: Observable<{ [key: string]: CorFilter }>;
  private updatesObserver: Subscriber<{ [key: string]: CorrespondenceFilterViewModel }>;
  constructor(
    @Inject(MapperServiceToken) private mapper: IMapperService
  ) {
    this.updates$ = new Observable(observer => {
      this.updatesObserver = observer
    });
  }

  get updates(): Observable<{ [key: string]: CorFilter }> { return this.updates$; };

  saveFilter(name: string, filter: CorFilter): void {
    let filters = this.getFilterObj();
    filters[name] = filter;
    localStorage["filters"] = JSON.stringify(filters);
    this.updatesObserver.next(filters);
  }

  removeFilter(name: string): void {
    let filters = this.getFilterObj();
    delete filters[name];
    localStorage["filters"] = JSON.stringify(filters);
    this.updatesObserver.next(filters);
  }

  getFilter(name: string): CorFilter {
    if (name=='awaiting-review') return this.awaitingReview();
    return this.mapper.MapJsonToVM(CorFilter, this.getFilterObj()[name]);
  }

  getAvailableFilterNames(): string[] {
    return Object.keys(this.getFilterObj());
  }

  private getFilterObj(): any {
    try {
      return JSON.parse(localStorage["filters"] || "{}");
    } catch (err) {
      return {};
    }
  }

  private awaitingReview(): CorFilter {
    let filter = new CorFilter();
    let subfilter = new CorSubFilter();
    subfilter.Type = CorFilterType.Status;
    subfilter.Keywords = ["Intake"];
    filter.Filters = [subfilter];
    return filter;
  }
}

export let CorrFilterServiceToken = new InjectionToken("CorrFilterService");