import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { RequestorCorrespondenceViewModel } from './requestor-correspondence-view-model';
import { RequestorViewModel } from './requestor-view-model';
import { endpoint, mappable } from '../decorators';
import { CategoryViewModel } from './category-view-model';

@endpoint("CorrespondenceCategory")
export class CorrespondenceCategoryViewModel extends BaseViewModel {
    public CorrespondenceId: number = 0;
    public CategoryId: number = 0;

    @mappable("CategoryViewModel")
    public Category: CategoryViewModel = null;
}
