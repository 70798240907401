<section id="review-details-container">
  <!-- Sub Header -->
  <app-header title='Edit Review'></app-header>
  <!-- Review Details -->
  <div class="container">
    <!-- View Mode -->
    <section class="info-box" *ngIf='! editMode'>
      <h1 class="in-box-heading">Review Details</h1>
        <div class="info-box-wrapper">
            <div class="info-box-body">   
              <div class="info-box-sections">
                <div class="info-section">
                  <h2>Correspondence Details</h2>
                  <h3>{{ corr && corr.Title }}</h3>
                  <p>{{ corr && corr.Summary }}</p>
                </div>
              </div>
              <p class="section-container">
                <i>*Subject to Open Records Requests</i>
              </p>       
              <div class="section-container">
                <h2>User</h2>
                <p>{{ review?.User?.FullName }}</p>
              </div>
              <div class="section-container">
                <h2>Type</h2>
                <p>{{ review?.Primary ? "Primary" : "Non-primary" }}</p>
              </div>
              <div class="section-container">
                <h2>Status</h2>
                <p>{{ review?.ReviewStatus }}</p>
              </div>
              <div class="section-container" *ngIf='auth.hasAnyRole("Shepherd", "Admin") || review?.Instructions'>
                <h2>Instructions to Reviewer</h2>
                <p>{{ review?.Instructions }}</p>
              </div>
              <div class="section-container">
                <h2>Add Review</h2>
                <p>{{ review?.ReviewText }}</p>
              </div>
            </div>
            <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
              <button mat-button mat-raised-button (click)='editReview()'>Edit</button>
            </div>
        </div>
    </section>
    <!-- Edit Mode -->
    <section *ngIf='editMode'>
      <form class="info-box" [formGroup]="myForm">
        <h1 class="info-box-heading">Edit Review Details</h1>
        <div class="info-box-wrapper">
          <div class="info-box-body">
            <div class="review-details info-box-sections">
              <div class="info-section">
                <h2>Correspondence Details</h2>
                <h3>{{ corr && corr.Title }}</h3>
                <p>{{ corr && corr.Summary }}</p>
              </div>
            </div>
            <p class="section-container">
              <i>*Subject to Open Records Requests</i>
            </p>       
            <div class="section-container user-type">
              <h2>User</h2>
              <p>{{ review?.User?.FullName }}</p>
            </div>
            <div class="section-container user-type">
              <h2>Type</h2>
              <p>{{ review && review.Primary ? "Primary" : "Non-primary" }}</p>
            </div>
            <fieldset>
                <div fxLayout="column">
                    <mat-form-field class="form-field-container" fxFlex>
                      <mat-select placeholder='Status' formControlName="status">
                        <mat-option *ngFor="let s of statuses" [value]="s.key">{{ s.value }}</mat-option>
                      </mat-select>
                    </mat-form-field> 
                    <mat-form-field class="form-field-container input" *ngIf='auth.hasAnyRole("Shepherd", "Admin")' fxFlex>
                      <textarea matInput placeholder="Instructions to Reviewer" formControlName="instructions"></textarea>
                    </mat-form-field>
                    <mat-form-field class="form-field-container input" fxFlex>
                      <textarea matInput placeholder="Review Text" formControlName="review"></textarea>
                    </mat-form-field>
                </div>
            </fieldset>
          </div>
          <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
            <button class="btn-save" mat-button mat-raised-button (click)='saveChanges()'>Save</button>
            <button mat-button mat-raised-button (click)='cancelEdit()'>Cancel</button>
          </div>
        </div>
      </form>
    </section>
    <!-- Add Attachments -->
    <section class="info-box">
        <h1 class="info-box-heading">Attachments</h1>
        <div class="info-box-wrapper">
            <div class="info-box-body">
               <mat-list class="info-box-list" fxLayout="column">
                    <mat-list-item  *ngFor="let doc of documents; let i=index; ">
                        <h2 mat-line>{{ doc.Filename }} {{ doc.Size | prettySize }}</h2>                                
                        <button mat-icon-button mat-raised-button (click)="download(doc.Id)">
                            <mat-icon class="mat-icon-dark">file_download</mat-icon>
                        </button>                          
                        <button mat-icon-button mat-raised-button (click)='removeFile(doc)'>
                            <mat-icon class="mat-icon-dark">remove</mat-icon>
                        </button>
                    </mat-list-item>
                </mat-list>
            </div>                
            <div class="info-box-btn" fxLayout="row" fxLayoutAlign="end">
                <button mat-button mat-raised-button [routerLink]="['./documents/add']">Add</button>
            </div>
        </div>
    </section>
    <!--<button type='button' mat-button mat-raised-button (click)='goToCorrespondence()'>Done</button>-->
  </div>
</section>