import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';

@Directive({
    selector: '[validateBeforeTomorrow][formControlName],[validateBeforeTomorrow][formControl],[validateBeforeTomorrow][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => BeforeTomorrowValidator), multi: true }
    ]
})
export class BeforeTomorrowValidator implements Validator {
    tomorrow: moment.Moment = moment().add(0, "days");
    constructor() {}
    
    validate(c: AbstractControl): { [key: string]: any } {
        let v = moment(c.value, "YYYY-MM-DD");

        // value not equal
        if (v >= this.tomorrow) return {
            beforeTomorrow: true
        }
        return null;
    }
}