<section id="help-container">
    <!-- Sub Header -->
    <app-header title='Help'></app-header>
    <div class="container">
        <section class="info-box">
            <h1 class="info-box-heading">Help</h1>
            <div class="info-box-wrapper">
                <div class="info-box-body">
                    <p>
                        This is the President's Office Correspondence Tracking application. If you believe you should have access, but are
                        unable to login, please contact Ben Held.
                    </p>

                    <h3 id="authorization">Authorization</h3>
                    <p>
                        In order to use certain parts of this system, you must be in the correct role. If you believe
                        you should be able to do something that you cannot, please contact an administrator of this system.
                    </p>
                    <p>
                        If you've been redirected to this message, its likely you've tried to access a restricted feature without
                        proper authorization.
                    </p>
                </div>
            </div>
        </section>
    </div>
</section>
