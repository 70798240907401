import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { DocumentViewModel } from './document-view-model';
import { ReviewViewModel } from './review-view-model';
import { endpoint, mappable } from '../decorators';

@endpoint("ReviewDocument")
export class ReviewDocumentViewModel extends BaseViewModel {
    public ReviewId: number = 0;
    public DocumentId: number = 0;

    @mappable("DocumentViewModel")
    public Document: DocumentViewModel = null;

    @mappable("ReviewViewModel")
    public Review: ReviewViewModel = null;
}
