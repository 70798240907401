import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { endpoint } from '../decorators/endpoint.decorator';
import { ContactDetailViewModel } from './contact-detail-view-model';
import { RequestorViewModel } from './requestor-view-model';
import { CorrespondenceViewModel } from './correspondence-view-model';
import { mappable } from '../decorators';
/**
 * This view model represents a relationship between Correspondences
 * and Requestors (whom the correspondences are from). 
 */
@endpoint("RequestorCorrespondence")
export class RequestorCorrespondenceViewModel extends BaseViewModel {
    public RequestorId: number = 0;
    public CorrespondenceId: number = 0;

    /** AKA who the correspondence is from */
    @mappable("RequestorViewModel")
    public Requestor: RequestorViewModel = null;

    @mappable("CorrespondenceViewModel")
    public Correspondence: CorrespondenceViewModel = null;

    /** Whether this is the primary requestor on a given correspondence. */
    public IsPrimary: boolean = false;
}
