import { BaseViewModel } from './base-view-model';
import { endpoint, mappable } from '../decorators';
import { UserRoleViewModel } from '.';

@endpoint("user")
export class UserViewModel extends BaseViewModel {
    public HawkId: string = "";
    public First: string = "";
    public Middle: string = "";
    public Last: string = "";
    public Email: string = "";
    public Department: string = "";
    public Title: string = "";
    public EmailNotifications: boolean = true;

    @mappable("UserRoleViewModel")
    public UserRoles: UserRoleViewModel[] = null;

    public get FullName(): string { return `${this.First} ${this.Last}`; }
    public get LastFirst(): string { return `${this.Last}, ${this.First} ${this.Middle || ""}`.trim(); }
    public get RolesSummary(): string { 
        return (this.UserRoles || [])
            .filter(ur => ur.Role && ur.Role.RoleName && ur.Role.RoleName != 'ValidUser')
            .map(ur => ur.Role.RoleName)
            .join(", ");
    }
}
