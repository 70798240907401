import { Component, OnInit, Inject, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RepositoryServiceToken } from '../../services';
import { IRepositoryService } from '../../services/i';
import { RequestorViewModel } from '../../view-models';
import { BaseFilterViewModel } from '../../view-models/filters';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  id: number = 0;
  contact: RequestorViewModel = null;
  filter: BaseFilterViewModel = new BaseFilterViewModel();
  @Input() keywords: FormControl = new FormControl();
  @Input() userRole: FormControl = new FormControl();
  @Input() form: FormGroup = new FormGroup({ keywords: this.keywords, role: this.userRole });

  // http://blog.angular-university.io/introduction-to-angular-2-forms-template-driven-vs-model-driven/
  // Regarding subscribe and emit: https://scotch.io/tutorials/angular-2-http-requests-with-observables
  // Automapper (if really necessary): https://github.com/loedeman/AutoMapper/wiki
  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    @Inject(RepositoryServiceToken) private repo: IRepositoryService
  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params["id"];
      this.repo
        .Get(RequestorViewModel, this.id)
        .subscribe(value => this.contact = value);
    });
  }

  ngOnInit() {
    this.keywords.setValue("");
    this.form = this.fb.group({
      names: this.fb.group({
        realName: this.fb.group({
          firstName: ['', [Validators.required]],
          middleName: [''],
          lastName: ['', Validators.required],
        }),
        alias: ['', Validators.required],
      }, { validator: this.eitherOr }),
    });
  }

  showRequiredError(fieldPath: string[]): boolean {
    return this.form.get(fieldPath).touched && this.form.hasError('required', fieldPath);
  }

  showCustomError(fieldPath: string[], error: string): boolean {
    return this.form.get(fieldPath).touched && this.form.hasError(error, fieldPath);
  }

  eitherOr(group: FormGroup) {
    for (let name in group.controls) {
      var ctl = group.controls[name];
      if (!ctl.errors)
        return null;
    }

    return { eitherOr: true };
  }
}

