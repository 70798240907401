
import {debounceTime} from 'rxjs/operators';
import { Component, OnInit, Inject, Injectable, EventEmitter, Input, InjectionToken } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { RepositoryService, RepositoryServiceToken, LogServiceToken, ErrorServiceToken } from '../../services';
import { IRepositoryService, ILogService, IErrorService } from '../../services/i';
import { UserViewModel, RoleViewModel } from '../../view-models';
import { BaseFilterViewModel, UserFilterViewModel, UserFilterType } from '../../view-models/filters';
import { IFilterViewModel } from '../../view-models/filters/i';
import { ConfirmDialog } from '../../dialogs';

@Component({
  selector: 'app-user-manager',
  templateUrl: './user-manager.component.html',
  styleUrls: ['./user-manager.component.css']
})
@Injectable()
export class UserManagerComponent implements OnInit {
  dialogRef: MatDialogRef<ConfirmDialog>;
  users: UserViewModel[] = [];
  order: string = 'LastFirst';
  roles: RoleViewModel[] = [];
  filter: UserFilterViewModel = new UserFilterViewModel();
  latestKeywords: string = null;
  latestRole: number = null;
  @Input() keywords: FormControl = new FormControl();
  @Input() userRole: FormControl = new FormControl();
  @Input() form: FormGroup = new FormGroup({ keywords: this.keywords, role: this.userRole });

  // http://blog.angular-university.io/introduction-to-angular-2-forms-template-driven-vs-model-driven/
  // Regarding subscribe and emit: https://scotch.io/tutorials/angular-2-http-requests-with-observables
  // Automapper (if really necessary): https://github.com/loedeman/AutoMapper/wiki
  constructor(
    @Inject(RepositoryServiceToken) private repo: IRepositoryService,
    @Inject(LogServiceToken) private log: ILogService,
    @Inject(ErrorServiceToken) private errors: IErrorService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.keywords.valueChanges.pipe(
      debounceTime(400))
      .subscribe(kw => { this.latestKeywords = kw; this.updateResults(); });
    this.userRole.valueChanges
      .subscribe(role => { this.latestRole = role; this.updateResults(); })
  }

  updateResults() {
      this.filter.Keywords = this.latestKeywords.split(" ");
      this.filter.Type = UserFilterType.RoleAndKeywords;
      this.filter.RoleId = this.latestRole;
      this.repo.Page(UserViewModel, this.filter)
        .subscribe(value => { this.users = value; });
  }

  openDialog(message: string): Promise<any> {
    this.dialogRef = this.dialog.open(
      ConfirmDialog, {
      disableClose: false
    });
    this.dialogRef.componentInstance.title = "Confirm Removal";
    this.dialogRef.componentInstance.message = message;

    this.dialogRef.afterClosed().subscribe(result => {
      this.dialogRef = null;
    });
    return this.dialogRef.afterClosed().toPromise();
  }

  removeUser(user: UserViewModel) {
    this.openDialog(`Are you sure you want to remove ${user.FullName}?`)
      .then(confirm => {
        if (confirm) {
          this.repo.Delete(UserViewModel, user.Id)
            .toPromise()
            .then(r => {
              this.updateResults();
            })
            .catch(e => {
              this.errors.broadcast("Error", UserManagerComponentToken, `Unabled to remove user ${user.FullName}.`);
              this.log.warn("Error: ", e);
            });
        }
      });
  }

  ngOnInit() {
    let blank = <any>{};
    blank.RoleName = '';
    blank.Id = '';
    this.keywords.setValue("");
    var roleFilter = new UserFilterViewModel();
    roleFilter.PageSize = 50;
    this.repo
      .Page(RoleViewModel, roleFilter)
      .subscribe(roles => { this.roles = roles; this.roles.unshift(blank); });
  }

}

export let UserManagerComponentToken = new InjectionToken("UserManagerComponent");
