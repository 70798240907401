<mat-sidenav-container mode="slide" opened="false" class="side-nav-container">
  <header>
    <mat-toolbar flex>
      <button *ngIf='authService.isAuthenticated' class="margin-right" mat-icon-button (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
      </button>
      <h1>
        {{title}}
      </h1>
      <span class="spacer"></span>
      <!--<button mat-icon-button [mdMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="mdMenu">-->
              <button class="login-logout-btn" mat-button *ngIf="!authService.isAuthenticated" (click)='login()'>
                Log In
              </button>
              <button class="login-logout-btn" mat-button *ngIf="authService.isAuthenticated" (click)='logout()'>
                Log Out
              </button>
      <!--</mat-menu>-->
    </mat-toolbar>
  </header>
  <mat-sidenav #sidenav role="navigation">
    <mat-card class='user-header'>
      <mat-card-header fxLayout="row" fxLayoutAlign="start center">
        <div mat-card-avatar class="card-avatar">
          <mat-icon mat-48>face</mat-icon>
        </div>
        <mat-card-title>{{ user && user.FullName || authService.username }}</mat-card-title>
      </mat-card-header>
    </mat-card>
    <mat-list>
      <mat-list-item *ngIf="authService.isAuthenticated">
        <button routerLink='/correspondences' (click)='sidenav.close()' mat-button aria-label='Correspondences'>
          <mat-icon>folder special</mat-icon>
          Correspondences
        </button>
      </mat-list-item>
      <mat-divider *ngIf='authService.isAuthenticated'></mat-divider>
      <mat-list-item secured>
        <button routerLink='/correspondence/add' (click)='sidenav.close()' mat-button aria-label="Add correspondence">
          <mat-icon>add</mat-icon>
          Add Correspondence
        </button>
      </mat-list-item>
      <mat-list-item *ngIf="authService.isAuthenticated && authService.hasAnyRole('Admin', 'Shepherd', 'Reviewer')">
        <button [routerLink]='["correspondences", "awaiting-review"]' (click)='sidenav.close()' mat-button aria-label='Correspondence awaiting review'>
          <mat-icon>drafts</mat-icon>
          <span *ngIf="counts">
            {{ counts[0]?.Count }}
          </span>
          Awaiting Review
        </button>
      </mat-list-item>
      <mat-divider *ngIf="filterNames?.length && authService.isAuthenticated"></mat-divider>
      <ng-template ngFor let-filterName let-i='index' [ngForOf]='filterNames'>
        <mat-list-item *ngIf="authService.isAuthenticated">
          <button [routerLink]='["correspondences", filterName]' (click)='sidenav.close()' mat-button aria-label='Custom-Filtered Correspondence'>
          <mat-icon>drafts</mat-icon>
          <span *ngIf="counts">
            {{ counts[i + 1]?.Count }}
          </span>
          Filter: {{ filterName }}
        </button>
          <span mat-button style='cursor: pointer' title='Remove filter' aria-label='Remove filter' (click)='removeFilter(filterName)'>X</span>
        </mat-list-item>
      </ng-template>
      <mat-divider *ngIf="authService.isAuthenticated"></mat-divider>
      <mat-list-item secured>
        <button routerLink='/users' (click)='sidenav.close()' mat-button aria-label="User Management">
          <mat-icon>face</mat-icon>
          User Management
        </button>
      </mat-list-item>
      <mat-list-item *ngIf="authService.isAuthenticated">
        <button routerLink='/settings' (click)='sidenav.close()' mat-button aria-label="Settings">
          <mat-icon>settings</mat-icon>
          Settings
        </button>
      </mat-list-item>
      <mat-list-item>
        <button routerLink='/help' (click)='sidenav.close()' mat-button aria-label="Help">
          <mat-icon>help</mat-icon>
          Help
        </button>
      </mat-list-item>
    </mat-list>
  </mat-sidenav>
  <!--<main role="main" id="wrapper"
    infinite-scroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="300"
    [scrollWindow]='false'
    infiniteScrollContainer='.mat-sidenav-content'
    (scrolled)="onScroll($event)">-->
     <main role="main" id="wrapper">
    <!-- login -->
    <!--<div class="container">
        <section class="info-box" *ngIf="!editMode">
            <h1 class="info-box-heading">Login</h1>
            <div class="info-box-wrapper">
                <div class="info-box-body">
                    <div class="section-container col-mat-12">
                      <p>Login using your Hawk identification</p>
                    </div>
                </div>
                <div class="info-box-btn" layout="row" layout-align="end">
                    <button mat-button mat-raised-button (click)='login()'>Login</button>
                </div>
            </div>
        </section>
    </div>-->
    <router-outlet></router-outlet>
  </main>
</mat-sidenav-container>