import { HttpParams } from '@angular/common/http';
import { BaseFilterViewModel } from './base-filter-view-model';
import { CorrespondenceSubFilterViewModel } from './correspondence-subfilter-view-model';
import { Operator } from './operator';
import { mappable } from '../../decorators';

export class CorrespondenceFilterViewModel extends BaseFilterViewModel {
    @mappable('CorrespondenceSubFilterViewModel')
    public Filters: CorrespondenceSubFilterViewModel[] = [];

    /** Whether to sort ascending on DateReceived. Default: descending (false). */
    public Ascending = false;

    public getAsHttpParams(): HttpParams {
        let params = super.getAsHttpParams();
        // params.append('filter.Ascending', `${this.Ascending}`);
        params = params.append(`filter.SortSpecifications[0].Property`, 'DateReceived');
        if (this.Ascending) {
          params = params.append(`filter.SortSpecifications[0].Direction`, '1');
        } else {
          params = params.append(`filter.SortSpecifications[0].Direction`, '2');
        }
        for (let i = 0; i < this.Filters.length; i++) {
          params = params.append(`filter.Filters[${i}].Type`, `${this.Filters[i].Type}`);
          params = params.append(`filter.Filters[${i}].Op`, `${this.Filters[i].Op}`);
          for (let j = 0; j < this.Filters[i].Keywords.length; j++) {
            params = params.append(`filter.Filters[${i}].Keywords`, `${this.Filters[i].Keywords[j]}`);
          }
        }
        return params;
    }
}
