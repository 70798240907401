export let receivedThroughDict: { [key: string]: string } = {
    "Email": "Email",
    "Postal Mail": "Postal Mail",
    "Phone": "Phone",
    "In-Person": "In-Person",
    "Text Message": "Text Message",
    "Parcel": "Parcel",
    "Other": "Other"
};

export let receivedThrough: string[] = Object.keys(receivedThroughDict).map(k => receivedThroughDict[k]);