import { Component, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { from } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { OAuthService, AuthConfig } from 'angular-oauth2-oidc';
import { MatMenuTrigger } from '@angular/material/menu';
import { BreadcrumbService } from 'ng-breadcrumb';

import { IRepositoryService, ICorrFilterService, IErrorService, IErrorMessage, ILogService } from './services/i';
import { AuthenticationServiceToken, RepositoryServiceToken, CorrFilterServiceToken, LogServiceToken,
  ErrorServiceToken, ScrollService, ScrollServiceToken, AuthenticationService } from './services';
import { CorrCountViewModel, UserViewModel } from './view-models';
import { CorrCountFilterViewModel } from './view-models/filters';
import { switchMap, filter, tap } from 'rxjs/operators';
import { CommunityAuthService } from './services/community-auth.service';

@Component({
  selector: '[app-root]',
  host: {
    //'(window:keydown)' : 'oauthService.bump()',
    //'(window.mousemove)' : 'oauthService.bump()'
  },
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  public user: UserViewModel;
  public filterNames: string[];

  constructor(
    @Inject(AuthenticationServiceToken) public authService: AuthenticationService,
    public commAuthService: CommunityAuthService,
    @Inject(RepositoryServiceToken) protected repo: IRepositoryService,
    @Inject(ErrorServiceToken) protected errors: IErrorService,
    @Inject(LogServiceToken) protected log: ILogService,
    @Inject(CorrFilterServiceToken) protected corrFilterSvc: ICorrFilterService,
    @Inject(ScrollServiceToken) protected scroll: ScrollService,
    @Inject("authConfig") protected authConfig: AuthConfig,
    protected breadcrumbService: BreadcrumbService,
    protected oauthService: OAuthService,
    protected toastr: ToastrService,
    protected vcr: ViewContainerRef  ) {
    this.oauthService.configure(authConfig);
    breadcrumbService.addCallbackForRouteRegex("#.*$", (path) => path.split("#")[0]);
    breadcrumbService.addCallbackForRouteRegex("\%[0-9]{2}", (path) => decodeURIComponent(path));
    breadcrumbService.addCallbackForRouteRegex('^.*[?].*$', (path) => path.split('?')[0]);
    breadcrumbService.hideRouteRegex("\/choose.*");
    breadcrumbService.hideRouteRegex("\/choose\/user.*");
    this.filterNames = this.corrFilterSvc.getAvailableFilterNames();

    this.corrFilterSvc.updates.subscribe(() => {
      this.filterNames = this.corrFilterSvc.getAvailableFilterNames();
    });  
    if (this.authService.isAuthenticated) {
        let cfilter = new CorrCountFilterViewModel();
        cfilter.Filters = this.corrFilterSvc.getAvailableFilterNames().map(f => this.corrFilterSvc.getFilter(f));
        cfilter.Filters.unshift(this.corrFilterSvc.getFilter('awaiting-review'));
        this.repo.Page(CorrCountViewModel, cfilter)
          .toPromise()
          .then(() => { });
    }

    from(this.commAuthService.runInitialLoginSequence())
            .pipe(
                switchMap(_ => this.commAuthService.isDoneLoading$),
                filter(isDone => isDone),
                switchMap(_ => this.commAuthService.isAuthenticated$),
                tap(async isAuth => {
                  if (isAuth) {
                    this.user = await repo.Lookup(UserViewModel, this.commAuthService.actualUsername).toPromise();
                    this.authService.setUserInfo(this.user);
                  } else {
                    this.log.warn("Not authenticated.");
                  }
                }, err => console.error(err))
            ).subscribe();

      this.errors.subscribeAll(this.unhandledGlobalErrors.bind(this));
  }

  ngOnInit() {
  }

  private removeFilter(name: string) {
    this.corrFilterSvc.removeFilter(name);
  }

  private unhandledGlobalErrors(payload: IErrorMessage) {
    console.error(payload);
    this.toastr.error(payload.message, `Error ${payload.type}`);
  }
  

  someMethod() {
    this.trigger.openMenu();
  }

  login(): void {
    this.authService.beginLoginFlow();
  }
  logout(): void {
    this.oauthService.logOut(false);
  }

  onScroll($event) {
    this.scroll.scroll($event);
  }

  title = 'CorTrack';
}
