import { BaseViewModel } from './base-view-model';
import { IViewModel } from './i-view-model';
import { endpoint, mappable } from '../decorators';

@endpoint("Correspondence")
export class CorrespondenceInputModel extends BaseViewModel  {
    /** This could be an email correspondence subject line, or intake staff's own title. */
    public Title: string = "";

    /** Could be the body of the email correspondence, or intake staff's summary. */
    public Summary: string = "";
    public RequestorName: string = "";
    public RequestorDetail: string = "";

    /** A short description of the correspondence source. E.g., email, text, phone, letter, news article, etc */
    public ReceivedThrough: string = "";

    public Status: string="";

    /** When the University received the correspondence. */
    public DateReceived: Date = null;

    /** When a reply is due, if ever. */
    public DueDate: Date = null;
}
