import { OnInit, Inject, Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Observer } from 'rxjs';


@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm.dialog.html',
  styleUrls: ['./confirm.dialog.css']
})

export class ConfirmDialog implements OnInit {
  title: string;
  actionBtn: string;
  cancelBtn: string;
  message: string;
    constructor(
      public dialogRef: MatDialogRef<ConfirmDialog>
    ) {
      
      //Observable.create()
    }

    ngOnInit() {
    }
}
