
import {forkJoin as observableForkJoin } from 'rxjs';

import {map, startWith} from 'rxjs/operators';
import { Component, OnInit, Inject, Injectable, Input } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment/moment';

import { states, priorities, receivedThrough } from '../../static-data';
import { RepositoryServiceToken, MapperServiceToken, LogServiceToken } from '../../services';
import { IRepositoryService, IMapperService, ILogService } from '../../services/i';
import { CorrespondenceViewModel as VMCorr, CorrespondenceViewModel, ContactDetailViewModel, RequestorViewModel, RequestorCorrespondenceViewModel } from '../../view-models';
import { CorrespondenceCategoryViewModel as CorrespondenceCategoryViewModel } from '../../view-models';
import { BaseFilterViewModel as CorFilter } from '../../view-models/filters';
import { IFilterViewModel } from '../../view-models/filters/i';
import { BeforeTomorrowValidator, AfterTodayValidator } from './validators';
import { CategoryViewModel } from '../../view-models'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-correspondence',
  templateUrl: './add-correspondence.component.html',
  styleUrls: ['./add-correspondence.component.css']
})
@Injectable()
export class AddCorrespondenceComponent implements OnInit {
  submitAttempted: boolean = false;
  correspondences: VMCorr[]=[];
  categories : CategoryViewModel[] = [];
  correspondenceCategories : CorrespondenceCategoryViewModel[] = [];
  filter: IFilterViewModel = new CorFilter();
  @Input() correspCat: FormControl = new FormControl();  
  filteredReceivedThrough: any;
  receivedThroughCommon: string[] = ["Email", "Phone", "Text Message", "Mail", "In-Person"];
  priorities: string[] = priorities;
  //correspCat: FormControl = new FormControl();  
  public myForm: FormGroup = null;
  states: string[] = states;
  receivedThrough: string[] = receivedThrough;

  // http://blog.angular-university.io/introduction-to-angular-2-forms-template-driven-vs-model-driven/
  // Regarding subscribe and emit: https://scotch.io/tutorials/angular-2-http-requests-with-observables
  // Automapper (if really necessary): https://github.com/loedeman/AutoMapper/wiki
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private location: Location,
    @Inject(MapperServiceToken) private mapper: IMapperService,
    @Inject(RepositoryServiceToken) private repo: IRepositoryService,
    @Inject(LogServiceToken) private log: ILogService,
    private toastr: ToastrService  ) {}

  ngOnInit() {
    //this.repo.Page(CategoryViewModel, this.filter).subscribe(cats => this.categories = cats);            
    this.myForm = this.fb.group({
      Type: ['', Validators.required],
      NewRequestor: this.fb.group({
        FirstName: [''],
        MiddleName: [''],
        LastName: [''],
        Details: [''],      
        Address: [''],
        City: [''],
        State: [''],
        Zip: [''],
        Phone: [''],
        Email: [''],
      }),
      Priority: ['Normal', Validators.required],
      Title: ['', Validators.required],
      Category: ['', Validators.required],
      Summary: [''],
      Notes: [''],
      ReceivedThrough: ['Postal Mail'],
      DateReceived: [
        moment().format("YYYY-MM-DD"), 
        [ new BeforeTomorrowValidator() ]
      ],
      DueDate: [
        moment().format("YYYY-MM-DD"),
        [ new AfterTodayValidator() ]
      ] 
    }, {
      validator: (group) => {
        return group.value.Type == "Incoming" && !(group.value.DateReceived && group.value.DueDate && group.value.ReceivedThrough)
               ? { datesRequired: true }
               : null;
      }
    });

    this.filteredReceivedThrough = this.myForm.controls["ReceivedThrough"].valueChanges.pipe(
      startWith(null),
      map(r => r ? this.receivedThroughCommon.filter(c => c.indexOf(r) >= 0) : this.receivedThroughCommon),);

    var filter = new CorFilter();
    filter.PageSize = 30;
    this.repo.Page(CategoryViewModel,filter).subscribe(cats => this.categories=cats);      
  }

  cvm : CorrespondenceViewModel;

  goBack() {
    this.location.back();
  }

  onsubmit()
  {
    this.submitAttempted = true;

    let ci = this.mapper.MapJsonToVM(CorrespondenceViewModel, this.myForm.value);
    ci.Id = 0;
    ci.Status = "Intake";

    if (this.myForm.valid) {
        this.repo.Create(CorrespondenceViewModel, ci)
          .toPromise()
          .then(this.onCreate.bind(this), e => this.log.error(e));
    } else {
      this.log.debug(this.myForm.errors, this.myForm);
    }
  }

  onCreate(v: VMCorr) {
    var ccms: CorrespondenceCategoryViewModel[] = [];

    for (let u of this.myForm.value.Category)
    {
        let ccm = new CorrespondenceCategoryViewModel ();
        ccm.Id=0;
        ccm.CorrespondenceId=v.Id;
        ccm.CategoryId=u;
        ccms.push(ccm);
    }
        this.repo
          .CreateMany(CorrespondenceCategoryViewModel, ccms)
          .toPromise()
          .then(() => {
              let contact = new RequestorViewModel();
              let r = this.myForm.value.NewRequestor;
              contact.FirstName = r.FirstName;
              contact.MiddleName = r.MiddleName;
              contact.LastName = r.LastName;
              contact.Notes = r.Details;
              return this.repo.Create(RequestorViewModel, contact).toPromise();
            })
          .then(rvm => {
            let details = new ContactDetailViewModel();
            let r = this.myForm.value.NewRequestor;
            details.RequestorId = rvm.Id;
            details.Address1 = r.Address;
            details.City = r.City;
            details.State = r.State;
            details.PostalCode = r.Zip;
            details.Phone = r.Phone;
            details.Email = r.Email;
            var join = new RequestorCorrespondenceViewModel();
            join.CorrespondenceId = v.Id;
            join.RequestorId = rvm.Id;

            let rdp = this.repo.Create(RequestorCorrespondenceViewModel, join).toPromise();
            let cdp = this.repo.Create(ContactDetailViewModel, details).toPromise();
            return observableForkJoin([rdp, cdp]).toPromise();
          })
          .then(() => {
              let message = `Correspondence "${(v.Title || "").substr(0, 20)}" has been created.`;
              this.toastr.success(message);
              this.router.navigate(['/correspondence', v.Id]);
            }, e => this.log.error(e));
  }

  showRequiredError(fieldPath: string[]): boolean {
    return this.submitAttempted && this.myForm.hasError('required', fieldPath);
  }

  showCustomError(fieldPath: string[], error: string): boolean {
    return this.submitAttempted && this.myForm.hasError(error, fieldPath);
  }
}
