import { Component, OnInit, Inject, Injectable, Input } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';

import { UserViewModel, UserFilterViewModel, UserFilterType } from '../../view-models';
import { RepositoryServiceToken, AuthenticationServiceToken, AuthenticationService } from '../../services';
import { IRepositoryService, IAuthenticationService } from '../../services/i';

@Component({
  selector: 'app-manage-settings',
  templateUrl: './manage-settings.component.html',
  styleUrls: ['./manage-settings.component.css']
})
// Purpose: expose the user's own User object for editing (contains their preferences).
@Injectable()
export class ManageSettingsComponent implements OnInit {
  user: UserViewModel;
  hasDailyEmailSummary: boolean = false;

  settingsForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(RepositoryServiceToken) private repo: IRepositoryService,
    @Inject(AuthenticationServiceToken) private auth: AuthenticationService
  ) {
      this.settingsForm = this.fb.group({
        Email: ['', [Validators.required]],
        EmailNotifications: [1]
      });
  }

  ngOnInit() {
    //this.keywords.setValue("");
    let filter = new UserFilterViewModel();
    filter.Type = UserFilterType.HawkId;
    filter.HawkId = this.auth.username;
    filter.Page = 0; filter.PageSize = 1;

    this.repo
      .Page(UserViewModel, filter)
      .subscribe(users => this.updateUI(users[0]));
  }

  updateUI(user: UserViewModel) {
    this.user = user;
    this.settingsForm.get("Email").setValue(this.user.Email);
    this.settingsForm.get("EmailNotifications").setValue(this.user.EmailNotifications ? "1" : "0");
  }

  save() {
    this.user.Email = this.settingsForm.value.Email;
    this.user.EmailNotifications = this.settingsForm.value.EmailNotifications == "1";
    this.repo.Update(UserViewModel, this.user)
      .toPromise()
      .then(u => this.updateUI(u));
  }

}
