import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Inject, ValueProvider, ClassProvider, Provider, FactoryProvider } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//'import { BrowserXhr } from '@angular/http';' is depricated a should be replaced
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RouterModule } from '@angular/router';
import { MomentModule } from 'ngx-moment';
import { OAuthModule, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgBreadcrumbModule } from 'ng-breadcrumb';
//import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { environment, OAuthConfig } from '../environments/environment';
import { BaseURI, APIBaseURI, AppConfigToken } from './app.config.tokens';

import { AuthGuard, RoleGuard } from './guards';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import {
  DashboardComponent, FilterViewerComponent,
  AddCorrespondenceComponent, AddDocumentComponent, CorrespondenceComponent, ManageContactsComponent,
  ManageSettingsComponent, HelpComponent, LoginComponent, ContactComponent, ContactDetailsComponent,
  UserManagerComponent,  UserDetailsComponent, AddUserComponent, UserChooserComponent,
  ReviewComponent, AddReviewComponent,
  DownloadComponent  } from './components';
import { FilterDialog } from './components/dashboard/filter.dialog';
import { ConfirmDialog, DownloadDialog } from './dialogs';
import { 
  RepositoryServiceToken, RepositoryService,
  AuthenticationService, AuthenticationServiceToken,
  RoleService, RoleServiceToken, 
  MapperServiceToken, MapperService,
  ErrorServiceToken, ErrorService,
  ProgressServiceToken, ProgressService,
  CorrFilterServiceToken, CorrFilterService,
  ScrollServiceToken, ScrollService,
  LogServiceToken

} from './services';
import { PrettySize, MapToIterable } from './pipes';
import { SecuredDirective } from './directives/secured.directive';
import { ProgressInterceptor } from './customizations-of-internals/progress-interceptor';
//'import { BrowserXhr } from '@angular/http';' is depricated a should be replaced
import { HeaderComponent } from './components/header/header.component';
import { FilteredDashboardComponent } from './components/filtered-dashboard/filtered-dashboard.component';
import { BeforeTomorrowValidator, AfterTodayValidator } from './components/add-correspondence/validators';
import { EitherOrValidator } from './components/contact/validators/either-or.validator';
import {OrderModule} from 'ngx-order-pipe';

///var es = new ErrorService();
export function storageFactory(): OAuthStorage {
  return localStorage;
}
export function getAccessToken() {
  return storageFactory().getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,    
    AddCorrespondenceComponent,
    AddDocumentComponent,
    AddReviewComponent,
    DownloadComponent,
    ManageContactsComponent,
    ManageSettingsComponent,
    CorrespondenceComponent,
    DashboardComponent,
    FilterViewerComponent,
    LoginComponent,
    HelpComponent,
    SecuredDirective,
    FilterDialog,
    ConfirmDialog,
    DownloadDialog,
    ContactComponent,
    ContactDetailsComponent,
    UserManagerComponent,
    UserDetailsComponent,
    AddUserComponent,
    UserChooserComponent,
    ReviewComponent,
    PrettySize,
    HeaderComponent,
    FilteredDashboardComponent,
    BeforeTomorrowValidator,
    AfterTodayValidator,
    MapToIterable,
    EitherOrValidator
  ],
  imports: [
    BrowserModule,
    OrderModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MomentModule,
    //Ng2CompleterModule,
    //InfiniteScrollModule,
    ToastrModule.forRoot(),
    OAuthModule.forRoot({ resourceServer: { allowedUrls: [environment.appConfig.APIBaseURI, environment.appConfig.OAuthConfig.loginUrl ], sendAccessToken: true }}),
    RouterModule.forRoot(routes),
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatToolbarModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    MatSidenavModule,
    MatSelectModule,
    MatDialogModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatChipsModule,
    FlexLayoutModule,
    NgBreadcrumbModule,
    HttpClientModule,
    JwtModule.forRoot(<JwtModuleOptions>{
      config: {
        tokenGetter: (getAccessToken)
      }
    })
  ],
  entryComponents: [
    FilterDialog,
    ConfirmDialog,
    DownloadDialog
  ],
  providers: [
    AuthGuard, RoleGuard,
    { provide: OAuthStorage, useFactory: storageFactory },
    <ValueProvider>{ provide: "authConfig", useValue: OAuthConfig },
    <ValueProvider>{ provide: BaseURI, useValue: environment.appConfig.BaseURI },
    <ValueProvider>{ provide: APIBaseURI, useValue: environment.appConfig.APIBaseURI },
    <ValueProvider>{ provide: AppConfigToken, useValue: environment.appConfig },
    <FactoryProvider>{ provide: LogServiceToken, useFactory: environment.appConfig.logFactory },
    <ClassProvider>{ provide: AuthenticationServiceToken, useClass: AuthenticationService },
    <ClassProvider>{ provide: RoleServiceToken, useClass: RoleService },
    <ClassProvider>{ provide: RepositoryServiceToken, useClass: RepositoryService },
    <ClassProvider>{ provide: CorrFilterServiceToken, useClass: CorrFilterService },
    <ClassProvider>{ provide: MapperServiceToken, useClass: MapperService },
    <ClassProvider>{ provide: ProgressServiceToken, useClass: ProgressService },
    { provide: HTTP_INTERCEPTORS, useClass: ProgressInterceptor, multi: true },
    //'BrowserXhr' is depricated a should be replaced
    <ClassProvider>{ provide: ScrollServiceToken, useClass: ScrollService },
    //<ClassProvider>{ provide: LocationStrategy, useClass: HashLocationStrategy }
    <ClassProvider>{ provide: ErrorServiceToken, useClass: ErrorService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    //private oauth: OAuthService<OAU, 
    //@Inject(AppConfigToken) private config: IAppConfig,
    //@Inject(LogServiceToken) private log: ILogService ) {
    /*this.oauth.loadDiscoveryDocument(config.OAuthConfig.DiscoveryDocument)
      .catch((reason: any) => {
        // fallback to local settings
        log.error("Warning: failed to load ITS AppDev OAuth discovery document. Falling back to hard-coded settings. Please investigate.");
        this.oauth.loginUrl = config.OAuthConfig.FallbackLoginUrl;
        this.oauth.issuer = config.OAuthConfig.FallbackIssuer;
        this.oauth.logoutUrl = config.OAuthConfig.FallbackLogoutUrl;
      });
    this.oauth.redirectUri = config.LoginReturnURI;
    this.oauth.clientId = config.OAuthConfig.ClientId;
    this.oauth.oidc = config.OAuthConfig.Oidc;
    this.oauth.setStorage(config.OAuthConfig.Storage);
    this.oauth.scope = config.OAuthConfig.Scope; */
  }
}
